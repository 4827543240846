import React, { useEffect, useRef } from 'react'
import { FormikHelpers, FormikProps } from 'formik'
import { View } from 'react-native'

import { LocaleT } from 'src/utils/translations/i18nTypes'
import { getApiUrl } from 'src/utils/apiConfig'
import { getServerMaintenanceInfo, loginUser } from 'src/store/user/actions'
import { removeConnectionChangeListener } from 'src/utils/networkStatus/listeners'
import Api from 'src/utils/api'
import i18n from 'src/utils/translations/i18n'
import LoginForm from './LoginForm'
import LogoHeader from '../LogoHeader'

import { bugsnagViewBreadcrumb } from 'src/utils/bugsnag'
import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import styles from './styles'
import { clearRequests, client } from 'src/utils/ApolloClient'
import { clearTokens, setRefreshToken, setToken } from 'src/utils/apolloStorage'
import LOGIN_MUTATION, { TLogin } from 'src/graphql/mutations/login'
import { loginMutation } from 'src/scenes/Welcome/Login/loginMutation'

interface InitialValuesTypes {
  countryCode: LocaleT
  email: string
  password: string
}

let emailCopy = '' // copy fields when view is restarted ie when pass is wrong
let passwordCopy = ''

export const Login = () => {
  const isConnected = useAppSelector(state => state.app.isConnected)
  const dispatch = useAppDispatch()
  const formRef = useRef<FormikProps<any>>(null)

  if (
    // @ts-ignore
    window.location.host === 'staging-portal.humly.co.uk' ||
    // @ts-ignore
    window.location.host === 'portal.humly.co.uk'
  ) {
    i18n.locale = 'en-GB'
  }

  useEffect(() => {
    clearTokens()
    client?.clearStore?.()
    clearRequests()
    bugsnagViewBreadcrumb('Login')
    getServerMaintenanceInfo()
  }, [])

  const onSubmit = async (
    { countryCode, email: tempEmail, password }: InitialValuesTypes,
    form: FormikHelpers<InitialValuesTypes>,
  ) => {
    const email = tempEmail.toLowerCase()
    emailCopy = email
    passwordCopy = password
    bugsnagActionBreadcrumb('login', { isConnected })
    clearRequests()
    clearTokens()
    client?.clearStore?.()

    if (isConnected) {
      const url = getApiUrl(countryCode)
      Api.changeUrl(url)

      const loginInput = { email, password }
      const loginMutationRequest = new loginMutation(formRef)
      const tokens = await loginMutationRequest
        ?.createClientWithoutToken(url)
        ?.mutate<TLogin>({
          mutation: LOGIN_MUTATION,
          variables: { loginInput },
        })
        .catch(error => {
          // need to catch error
          console.error(error)
        })

      const token = tokens?.data?.login?.accessToken
      const refreshToken = tokens?.data?.login?.refreshToken

      if (token) {
        setToken(token)
        setRefreshToken(refreshToken)

        return dispatch(loginUser({ countryCode, email, password, setErrors: form.setErrors }))
      }
      form.setSubmitting(false)
      return Promise.resolve()
    } else {
      form.setSubmitting(false)
      return Promise.resolve()
    }
  }

  return (
    <View style={styles.page}>
      <LogoHeader />
      <LoginForm emailCopy={emailCopy} onSubmit={onSubmit} passwordCopy={passwordCopy} formRef={formRef} />
    </View>
  )
}

export default Login
