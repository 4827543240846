export const ANDROID_APP_ID = process.env.ANDROID_APP_ID
export const APPLE_ID = process.env.APPLE_ID
export const APPLE_STORE_ID = process.env.APPLE_STORE_ID
export const BUGSNAG_API_KEY = process.env.BUGSNAG_API_KEY
export const BUILD_MODE = process.env.BUILD_MODE
export const CONFIGCAT_SDK_DEV_KEY = process.env.CONFIGCAT_SDK_DEV_KEY
export const CONFIGCAT_SDK_PROD_KEY = process.env.CONFIGCAT_SDK_PROD_KEY
export const DEV_USER_EMAIL = process.env.DEV_USER_EMAIL
export const DEV_USER_PWD = process.env.DEV_USER_PWD
export const ENCRYPTED_TOKEN_KEY = process.env.ENCRYPTED_TOKEN_KEY
export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY
export const GOOGLE_MAPS_API_KEY_WEB_DEV = process.env.GOOGLE_MAPS_API_KEY_WEB_DEV
export const GOOGLE_MAPS_API_KEY_WEB_PROD = process.env.GOOGLE_MAPS_API_KEY_WEB_PROD
export const MIXPANEL_API_KEY_PROD = process.env.MIXPANEL_API_KEY_PROD
