import React, { useContext, useEffect, useRef, useState } from 'react'
import { View, Text, ScrollView } from 'react-native'
import { useQuery } from '@apollo/client'

import { CompleteTaskError, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { useBugsnagView } from 'src/utils/bugsnag'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox/Checkbox'
import FileUploader from 'src/components/FileUploader/FileUploader'
import LoadingIndicator from 'src/components/LoadingIndicator'
import OtherDocumentsQuery, { OtherDocumentsRequestTypes } from 'src/graphql/OtherDocuments'
import translations, { translate } from 'src/utils/translations/translations'
import useBackModal, { RefType } from 'src/hooks/useBackModal'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'OtherDocuments'>

const OtherDocumentsSv = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask - OtherDocumentsSv')

  const userId = useAppSelector(state => state.user.id) as string

  const [isError, setIsError] = useState(false)
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [noDocumentsToUpload, setNoDocumentsToUpload] = useState(false)
  const [shouldSaveDocument, setShouldSaveDocument] = useState(false)

  const { data, refetch, loading } = useQuery<OtherDocumentsRequestTypes>(OtherDocumentsQuery, {
    variables: {
      id: userId,
    },
    fetchPolicy: 'network-only',
    onCompleted: res => !res.user.uploadedFiles.length && isCompleted && setNoDocumentsToUpload(true),
  })

  const { mixpanel } = useContext(MixpanelContext)

  const documentRef = useRef<RefType>({ dirty: false })

  useEffect(() => {
    if (documentRef.current) {
      documentRef.current.dirty = isFilePicked
    }
    if (isCompleted) setNoDocumentsToUpload(!isFilePicked)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilePicked])

  const closeScreen = useBackModal(navigation, documentRef)
  const handleError = () => {
    setIsSubmitting(false)
    setIsError(true)
  }
  const handleSubmitFinish = () => {
    setIsSubmitting(false)
    closeScreen()
  }
  const handleOtherDocumentsCallback = () => {
    refetch()
    handleSubmitFinish()
  }

  const isDocumentUploaded = data?.user.uploadedFiles?.length || isFilePicked
  const isButtonDisabled =
    (!noDocumentsToUpload && !isFilePicked && !data?.user.uploadedFiles.length) || isCompleted || isSubmitting

  const updateTaskStatus = () =>
    markTaskAsCompleted(userId, task, isFilePicked, mixpanel, handleSubmitFinish, handleError)

  const completeTask = () => {
    setIsSubmitting(true)
    setIsError(false)
    isFilePicked ? setShouldSaveDocument(true) : updateTaskStatus()
  }

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      <Text style={styles.mainInfo}>{translate(translations.otherDocumentsDescription)}</Text>
      <FileUploader
        arrayDocuments={data?.user.uploadedFiles || []}
        buttonDisabled={isCompleted}
        deletingFilesDisabled={isCompleted}
        field="others"
        onFileSaveCallback={updateTaskStatus}
        onFilesChangeCallback={({ uploadedFiles }) => setIsFilePicked(!!uploadedFiles?.length)}
        onFilesErrorCallback={handleError}
        onOtherDocumentsCallback={handleOtherDocumentsCallback}
        saveOnUpload={shouldSaveDocument}
      />
      <View style={styles.checkboxContainer}>
        <Checkbox
          disabled={!!isDocumentUploaded || isCompleted}
          label={translate(translations.otherDocumentsCheckbox)}
          onPress={() => setNoDocumentsToUpload(prev => !prev)}
          selected={noDocumentsToUpload}
        />
      </View>
      {isError ? (
        <View style={styles.alertWrapper}>
          <CompleteTaskError hideError={() => setIsError(false)} />
        </View>
      ) : null}
      <View style={styles.buttonContainer}>
        <ButtonNew
          disabled={isButtonDisabled}
          isSubmitting={isSubmitting}
          onPress={completeTask}
          size={buttonSize.lg}
          title={translate(translations.closeTask)}
          variant={buttonVariants.containedDefault}
        />
      </View>
      <Text style={styles.taskInfo}>{translate(translations.otherDocumentsSubinfo)}</Text>
    </ScrollView>
  )
}

export default OtherDocumentsSv
