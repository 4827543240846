import React, { useCallback, useContext } from 'react'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { ScrollView, Text } from 'react-native'

import { ChevronRightIcon } from 'src/icons'
import { getUserCompetences, isUserApplicant } from 'src/store/user/selectors'
import { isGBSelector } from 'src/store/app/selectors'
import { jobOfferItemData } from '../utils'
import { JobsNavigatorScreensProps } from 'src/utils/types/navigationTypes'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { navigateToAppScreen } from 'src/utils/navigateToScreen'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { useAppState } from 'src/utils/AppStateEvent'
import { useBugsnagView } from 'src/utils/bugsnag'
import { userOnHoldReasons } from 'src/global/constants'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import BlockedScreen from './BlockedScreen'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import globalStyles from 'src/global/globalStyles'
import JobsSectionList, { FakeEmptyItemType, RenderListItemInfo } from 'src/components/List/JobsSectionList'
import LongTermAndExpressSection from './LongTermAndExpressSection'
import Placeholder from './Placeholder/Placeholder'
import ShortTermOfferItem from '../../Rows/ShortTermOfferItem/ShortTermOfferItem'
import styleList from 'src/components/List/styles'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import useJobsOffersList from 'src/scenes/Main/Jobs/Lists/Requests/useJobsOffersList'
import useOnHoldData from 'src/hooks/useOnHoldData'

export const Requests = () => {
  const educatorCompetences = useAppSelector(getUserCompetences)
  const isApplicant = useAppSelector(isUserApplicant)
  const isGB = useAppSelector(isGBSelector)
  const navigation = useNavigation<JobsNavigatorScreensProps<'Requests'>['navigation']>()
  const { mixpanel } = useContext(MixpanelContext)

  const {
    endOnHoldState,
    isLoading: isOnHoldDataLoading,
    isOnHold,
    onHoldAtFormatted,
    onHoldReason,
    refetch: refetchOnHoldData,
    successfullyEndedOnHold,
  } = useOnHoldData()

  const {
    expressCount,
    isExpressAndLongTermLoading,
    isExpressShouldBeVisible,
    isLoading,
    longTermCount,
    refreshFunction,
    shortTermOffers,
  } = useJobsOffersList()

  useBugsnagView('Requests')
  //@ts-ignore

  useAppState({
    onForeground: refreshFunction,
  })

  useFocusEffect(
    useCallback(() => {
      refetchOnHoldData()
      refreshFunction()
      mixpanel?.track('Find Jobs', { action: 'open' })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  )

  const openRecruitmentTab = () => navigateToAppScreen(['MainTabNavigator', 'OnboardingView'])

  const showOnHoldAlert =
    isOnHold &&
    !successfullyEndedOnHold &&
    (onHoldReason === userOnHoldReasons.perm || onHoldReason === userOnHoldReasons.privateReasons)

  const renderListHeaderAlert = useCallback(
    () => (
      <>
        {showOnHoldAlert ? (
          <AlertCard
            additionalAction={{
              isSubmitting: isOnHoldDataLoading,
              onPress: endOnHoldState,
              title: translate(translations.backToWork),
            }}
            colorSchema={alertColorScheme.info}
            description={translate(translations.endOnHold)}
            style={styles.alert}
            title={translate(translations.onHold)}
            variant={alertVariants.standard}
          />
        ) : null}
        {successfullyEndedOnHold ? (
          <AlertCard
            colorSchema={alertColorScheme.success}
            description={translate(translations.successfullyEndedOnHoldState)}
            style={styles.requestAlert}
            variant={alertVariants.filled}
          />
        ) : null}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOnHoldDataLoading, showOnHoldAlert, successfullyEndedOnHold],
  )

  const renderListHeader = useCallback(
    () => (
      <>
        {renderListHeaderAlert()}
        <LongTermAndExpressSection
          expressCount={expressCount}
          isExpressAndLongTermLoading={isExpressAndLongTermLoading}
          isExpressExists={isExpressShouldBeVisible}
          longTermCount={longTermCount}
          removeContainerMargin={showOnHoldAlert || successfullyEndedOnHold}
        />
        <Text style={styles.header}>{translate(translations.availableShortTermOffers)}</Text>
        {isApplicant && (
          <>
            <AlertCard
              colorSchema={alertColorScheme.info}
              description={translate(translations.completeRecruitmentProcessDescription)}
              title={translate(translations.completeRecruitmentProcess)}
              variant={alertVariants.standard}
            />
            <ButtonNew
              isLong
              onPress={openRecruitmentTab}
              RightIcon={ChevronRightIcon}
              style={styles.applicantGotoRecruitmentButton}
              testID="accept-button"
              title={translate(translations.goToRecruitment)}
              variant={buttonVariants.containedDefault}
            />
          </>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      endOnHoldState,
      expressCount,
      isApplicant,
      isExpressAndLongTermLoading,
      isExpressShouldBeVisible,
      isOnHoldDataLoading,
      longTermCount,
      showOnHoldAlert,
      successfullyEndedOnHold,
    ],
  )

  const shouldBlockView = isOnHold && onHoldReason === userOnHoldReasons.ongoingInvestigation

  if (shouldBlockView) {
    return <BlockedScreen onHoldAt={onHoldAtFormatted} />
  }

  if (isApplicant) {
    return (
      <ScrollView contentContainerStyle={{ ...styleList.container, ...globalStyles.containerSize }} testID="scrollView">
        {renderListHeader()}
      </ScrollView>
    )
  }

  const renderJobListItem = ({ item }: RenderListItemInfo) => {
    if (['Proposal', 'ExpressBooking'].includes(item.__typename)) return undefined as never

    if (item.id === 'empty') {
      return <Text style={styles.emptyMessage}>{(item as FakeEmptyItemType).text}</Text>
    }

    const navigateToDetails = () => navigation.navigate(item.__typename, { id: item.id })
    return (
      <ShortTermOfferItem {...jobOfferItemData(item, isGB, false, educatorCompetences)} onPress={navigateToDetails} />
    )
  }

  return (
    <JobsSectionList
      refreshFunction={refreshFunction}
      ListEmptyComponent={<Placeholder isLoading={isLoading} />}
      ListHeader={renderListHeader()}
      renderJobListItem={renderJobListItem}
      sections={shortTermOffers}
    />
  )
}

export default Requests
