import React, { useEffect, useState } from 'react'
import { Linking } from 'react-native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import SplashScreen from 'src/utils/webAdapters/SplashScreenAdapter'
import { enableScreens } from 'react-native-screens'

import Loading from 'src/utils/Loading'
import LoginRoutes from './scenes/Welcome/LoginRoutes'
import PrivateRoutes from './PrivateRoutes'
import { bugsnagNotifyWithData, bugsnagViewBreadcrumb } from 'src/utils/bugsnag'
import { MainParamList } from './utils/types/navigationTypes'
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks'
import URL from 'url'
import { DEEP_LINK_PATH_PREFIX, DEFAULT_PAGE, MAIN_TAB_NAVIGATOR_PATTERN } from './hooks/useNavigateToDeepLink'
import { setDeepLink } from './store/app/actions'
import { useLinkTo } from '@react-navigation/native'

const Stack = createNativeStackNavigator<MainParamList>()

const options = {
  header: () => null,
  gesturesEnabled: false,
}

enableScreens()

const Routes = () => {
  const isLoading = useAppSelector(state => state.app.isLoading)
  const userId = useAppSelector(state => state.user.id)
  const userPersist = useAppSelector(state => state.user?._persist?.rehydrated) || true
  const dispatch = useAppDispatch()
  const [isInitialUrlHandled, setInitialUrlHandled] = useState(false)
  const linkTo = useLinkTo()

  const appIsLoading = isLoading || !userPersist
  const appIsLoaded = !isLoading && userPersist

  useEffect(() => {
    if (appIsLoaded) {
      bugsnagViewBreadcrumb('appIsLoaded in Routes')

      setTimeout(SplashScreen.hide, 500)
    }
  }, [appIsLoaded])

  /** Opens deep links
   *
   * There are three parts:
   * 1. Storing the incoming URL for `navigateToDeepLink` to use
   *    - Deep links navigating to a screen nested in MainTabNavigator cause a
   *      problem when the user wants to go back to the root view of the stack.
   *      The nested page becomes the initial page of the stack, making it
   *      impossible to navigate to the actual root view.
   *      Therefore, an additional navigation to the root view is inserted
   *      before navigating to the actual requested url for nested views.
   * 2. Setting isInitialUrlHandled to make sure initial link is handled only
   *    once per app launch
   * 3. Navigating to the default page (Jobs), where the deep link calls happen
   */

  const handleOpenURL = React.useCallback((url: string) => {
    const { pathname, search } = URL.parse(url)
    if (!pathname || pathname === '/') return

    const [parentLink] = pathname.match(MAIN_TAB_NAVIGATOR_PATTERN) ?? []
    const deepLink = parentLink
      ? {
          parentLink,
          nestedLink: pathname + (search ?? ''),
        }
      : {
          parentLink: pathname + (search ?? ''),
          nestedLink: undefined,
        }
    dispatch(setDeepLink(deepLink))
    setInitialUrlHandled(true)
    linkTo(DEEP_LINK_PATH_PREFIX + DEFAULT_PAGE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    Linking.getInitialURL()
      .then((link: string | null) => {
        if (!link || !appIsLoaded || !userId || isInitialUrlHandled) return

        handleOpenURL(link)
      })
      .catch(err => {
        bugsnagNotifyWithData('DeepLink Error', err)
      })

    const listener = Linking.addEventListener('url', event => handleOpenURL(event?.url))

    return () => {
      listener.remove()
    }
  }, [handleOpenURL, appIsLoaded, userId, isInitialUrlHandled])

  return (
    <Stack.Navigator>
      {appIsLoading && <Stack.Screen component={Loading} name="Loading" options={options} />}
      {appIsLoaded && !userId && <Stack.Screen component={LoginRoutes} name="LoginRoutes" options={options} />}
      {appIsLoaded && userId && <Stack.Screen component={PrivateRoutes} name="PrivateRoutes" options={options} />}
    </Stack.Navigator>
  )
}

export default Routes
