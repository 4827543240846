import { Alert, Platform } from 'react-native'
import DeviceInfo from 'src/utils/webAdapters/DeviceInfoAdapter'
import messaging, { FirebaseMessagingTypes } from 'src/utils/webAdapters/FirebaseMessageAdapter'
import { RESULTS } from 'src/utils/webAdapters/RNPermissionsAdapter'

import { bugsnagActionBreadcrumb, bugsnagNotifyWithData } from 'src/utils/bugsnag'
import translations, { translate } from 'src/utils/translations/translations'
import { ValuesResults } from './types'
import store from 'src/store'
import { PassAccessNotificationProvider } from 'src/context/NotificationContext'
import notificationMutation from 'src/graphql/mutations/notificationCallback'
import { offlineLink } from 'src/utils/apolloOfflineMutation'

export const requestGetNotification = async (notification: any, mess: string, isOpened = false) => {
  if (
    notification &&
    Object.keys(notification).length &&
    notification?.data &&
    Object.keys(notification?.data).length
  ) {
    try {
      const notificationRequestBody = {
        mutation: notificationMutation,
        variables: {
          dateReceived: new Date()?.toISOString(),
          messageID: notification?.data?.messageId,
          opened: isOpened,
        },
        optimisticResponse: {
          pushNotificationCallback: true,
        },
      }

      if (!PassAccessNotificationProvider?.offlineClient && offlineLink) {
        offlineLink.add(notificationRequestBody)
      } else {
        PassAccessNotificationProvider?.offlineClient?.mutate(notificationRequestBody).catch(err => {
          console.error(err, 'requestGetNotification error')
        })
      }
    } catch (_) {
      bugsnagActionBreadcrumb('requestGetNotification error', { notification, mess })
    }
  }
}

export const isAuthStatusEnabled = (authStatus: ValuesResults) =>
  // @ts-ignore
  [RESULTS.GRANTED, RESULTS.LIMITED].includes(authStatus)

export const isDevice = async () => {
  const isSimulator = await DeviceInfo.isEmulator()
  const isWeb = Platform.OS === 'web'

  return !isSimulator && !isWeb
}

export const handlePermissionError = (err: FirebaseMessagingTypes.SendErrorEvent) => {
  bugsnagNotifyWithData('Notification permission error', err)
  Alert.alert(translate(translations.pushNotificationsNoPermissions))
}

export const unregisterPushNotifications = async () => {
  const device = await isDevice()
  const isConnected = store?.getState?.()?.app?.isConnected

  if (device && isConnected && messaging) {
    await messaging?.().deleteToken?.()
    await messaging?.().unregisterDeviceForRemoteMessages?.()
  }
}
