import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { View, StyleSheet } from 'react-native'

import { bugsnagViewBreadcrumb } from 'src/utils/bugsnag'
import { CustomModalProps } from './types'
import styles from './styles'

const CustomModalWrapper = (props: CustomModalProps) => {
  const openModal = props.isVisible
  const isModalScreen = props.isModalScreen

  useEffect(() => {
    if (openModal) {
      bugsnagViewBreadcrumb('CustomModal', { modalPlace: props?.modalPlace, title: props?.title })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal])

  if (!openModal) return null

  return isModalScreen ? (
    <View style={styles.webContainer}>
      <View style={StyleSheet.flatten([styles.modalWrapper, styles.modalWrapperWeb])}>{props.children}</View>
    </View>
  ) : (
    ReactDOM.createPortal(
      <View style={styles.webContainer}>
        <View style={StyleSheet.flatten([styles.modalWrapper, styles.modalWrapperWeb])}>{props.children}</View>
      </View>,
      document.querySelector('#modal'),
    )
  )
}

export default CustomModalWrapper
