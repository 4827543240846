import { createSelector } from 'reselect'
import get from 'lodash/get'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { setTimezone, timezoneByCountryCode } from 'src/global/utils'
import { CurrencyType, setFormatter } from './formatter'
import { getLanguageByLocation, setLocale } from 'src/utils/translations/i18n'
import { UserResponseT } from '../store/user/actionTypes'
import { LocaleT, SmallLocationT } from './translations/i18nTypes'
import { RegionAttributesT, UserT } from 'src/store/user/userTypes'
import countryLocalizationInfo from 'src/global/countryLocalizationInfo'
import storage from 'src/mmkvStorage'

const getRelative = (data = {} as UserResponseT) => filter(data.included, ({ type }) => type === 'relatives')[0] || {}

const getAttributes = (data = {} as UserResponseT) => data?.data?.attributes || {}

const getRegion = (data: UserResponseT) =>
  (filter(data.included, ({ type }) => type === 'regions')[0] || {}) as UserT['region']

const getJoinedAttributes = createSelector(getRelative, getAttributes, (relative, attributes) =>
  Object.assign(attributes, {
    relative: { id: relative.id, ...get(relative, 'attributes', {}) },
  }),
)

const getTimezone = createSelector(
  getRegion,
  region => region.attributes && (region.attributes as RegionAttributesT)?.timezone,
)

export const getLocale = createSelector(getRegion, region => {
  let newLocale = ''

  if (region.attributes.locale) {
    if (region?.attributes?.locale?.includes('en')) {
      newLocale = countryLocalizationInfo.gb.locale
    } else {
      newLocale = region.attributes.locale
    }
  } else newLocale = storage.getString('locale') as string

  return newLocale
})

const getCurrency = createSelector(getRegion, region =>
  region.attributes ? region.attributes.currency : storage.getString('currency'),
)

const checkWorkload = (user: UserResponseT['data'], userCountryCode: LocaleT) => {
  if (!userCountryCode) {
    return
  }
  if (!isEmpty(get(user, 'attributes.desiredWorkloads', []))) {
    AsyncStorage.setItem('workloadFilled', 'true')
  } else {
    AsyncStorage.setItem('workloadFilled', 'false')
  }
}

export const createUser = (data: UserResponseT, countryCode: LocaleT): UserT => {
  const joinedAttributes = getJoinedAttributes(data)
  checkWorkload(data.data, countryCode)

  const timezone: UserT['timezone'] = getTimezone(data) || timezoneByCountryCode(countryCode)
  setTimezone(timezone, countryCode)

  const newLocale = setLocale(countryCode)
  setFormatter(getLanguageByLocation(countryCode).toLowerCase() as SmallLocationT, getCurrency(data) as CurrencyType)

  const region = getRegion(data)
  const marketId = data.data.attributes.marketId

  return {
    attributes: joinedAttributes,
    contractUrl: get(joinedAttributes, 'contractUrl'),
    countryCode,
    id: data.data.id,
    included: data.included,
    locale: newLocale,
    region,
    marketId,
    timezone,
    userState: get(joinedAttributes, 'state'),
  }
}
