import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useFocusEffect } from '@react-navigation/native'

import { applicationStatus } from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/applicationTypes'
import {
  formatWorkPermitExpiredDate,
  getJobAlertDataBasedOnCompliance,
  getScreenTitle,
  isUserWorkPermitValid,
  lockedOffers,
  pendingApplications,
} from '../utils'
import {
  JobsNavigatorScreensProps,
  MenuNavigatorScreensProps,
  NotificationsScreensProps,
  ScheduleNavigatorScreensProps,
} from 'src/utils/types/navigationTypes'
import { ComplianceStatusContext } from 'src/context/ComplianceStatusContext'
import { MixpanelContext } from 'src/context/MixpanelContext'
import AcceptOverlay from './AcceptOverlay'
import AlertMessage from 'src/scenes/Main/Jobs/Details/components/AlertMessage'
import FetchingDataErrorModal from 'src/components/ModalTypes/FetchingDataErrorModal/FetchingDataErrorModal'
import JobDetails from 'src/scenes/Main/Jobs/Details/components/JobDetails'
import Loader from 'src/scenes/Main/Jobs/Details/components/Loader'
import LoadingIndicator from 'src/components/LoadingIndicator'
import useBookingData from 'src/hooks/useBookingData'
import useTabBarOverlay from 'src/hooks/useTabBarOverlay'
import WithdrawInterestOverlay from './WithdrawInterestOverlay'
import { getMixpanelBookingDay } from './utils'
import { getUserWorkPermitInfo } from 'src/store/user/selectors'
import { isSESelector } from 'src/store/app/selectors'
import { useAppSelector } from 'src/hooks/reduxHooks'

type BookingNavigationType =
  | JobsNavigatorScreensProps<'Booking'>
  | MenuNavigatorScreensProps<'BookingMenu'>
  | ScheduleNavigatorScreensProps<'BookingSchedule'>
  | NotificationsScreensProps<'BookingNotification'>

const Booking = ({ route }: BookingNavigationType) => {
  const id = route?.params?.id as string

  const {
    appointedBy,
    applicationId,
    bookingAlertData,
    bookingCalendarData,
    bookingDetails,
    bookingStatus,
    error,
    jobConfirmationData,
    loading,
    refetch,
  } = useBookingData(id)
  const [isError, setIsError] = useState(false)
  const { isUserCompliant } = useContext(ComplianceStatusContext)
  const { mixpanel } = useContext(MixpanelContext)
  const isSE = useAppSelector(isSESelector)
  const { workPermitExpiresOn, workPermitRequired } = useAppSelector(getUserWorkPermitInfo)

  const showAcceptOverlay = bookingStatus && pendingApplications.includes(bookingStatus)
  const showWithdrawInterestOverlay = bookingStatus === applicationStatus.userApplied
  const showMask =
    bookingDetails.isSickLeaves ||
    bookingStatus === applicationStatus.offerCancelled ||
    bookingStatus === applicationStatus.offerAppointToOtherTeacher
  const showOverlay = showAcceptOverlay || showWithdrawInterestOverlay
  const isNavigationBlocked = bookingStatus && lockedOffers.includes(bookingStatus) && !isError

  const screenTitle = bookingStatus ? getScreenTitle(bookingStatus) : ''

  const { isCustomOverlay, goBack } = useTabBarOverlay(showOverlay, screenTitle, isNavigationBlocked)

  const [isUpdating, setIsUpdating] = useState(false)
  const [showJobConfirmation, setShowJobConfirmation] = useState(false)

  const workPermitExpiresOnFormatted = formatWorkPermitExpiredDate(workPermitExpiresOn)

  const isUserWorkPermitInvalid = isSE
    ? false
    : !isUserWorkPermitValid({
        jobLatestDate: bookingCalendarData?.startTime,
        workPermitExpiresOn,
        workPermitRequired,
      })

  const userComplianceAlertData = useMemo(
    () =>
      getJobAlertDataBasedOnCompliance({
        isUserCompliant,
        isUserWorkPermitInvalid,
        workPermitExpiresOn: workPermitExpiresOnFormatted,
      }),
    [isUserCompliant, isUserWorkPermitInvalid, workPermitExpiresOnFormatted],
  )

  const isConfirmed = bookingStatus === applicationStatus.userAppointed
  const isPreschool = bookingDetails.isPreschool
  const isNorthpassButtonDisplay = isSE && isConfirmed && isPreschool

  const onOpen = () =>
    mixpanel?.track('Booking Details', {
      appointedBy,
      bookingId: id,
      day: getMixpanelBookingDay(bookingCalendarData?.startTime),
      status: bookingStatus,
    })

  useFocusEffect(
    useCallback(() => {
      refetch && refetch()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  )

  if (error) {
    return <FetchingDataErrorModal errorToReport={error} />
  }

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <>
      {isUpdating ? <Loader /> : null}

      <JobDetails
        {...bookingDetails}
        isNorthpassButtonDisplay={isNorthpassButtonDisplay}
        loading={loading}
        onOpen={onOpen}
        refetch={refetch}
        showMask={showMask}
        hideSalary={bookingStatus === applicationStatus.offerAppointToOtherTeacher}
      >
        {!isUpdating ? (
          <AlertMessage
            alertData={bookingAlertData}
            offerStatus={bookingStatus}
            showJobConfirmation={showJobConfirmation}
            userComplianceAlertData={userComplianceAlertData}
          />
        ) : null}
      </JobDetails>

      {isCustomOverlay && showAcceptOverlay ? (
        <AcceptOverlay
          bookingCalendarData={bookingCalendarData}
          bookingId={id}
          bookingStatus={bookingStatus}
          goBack={goBack}
          isUpdating={isUpdating}
          isUserWorkPermitInvalid={isUserWorkPermitInvalid}
          jobConfirmationData={jobConfirmationData}
          refetch={refetch}
          setIsError={setIsError}
          setIsUpdating={setIsUpdating}
          setShowJobConfirmation={() => setShowJobConfirmation(true)}
          workPermitExpiresOn={workPermitExpiresOnFormatted}
        />
      ) : null}

      {isCustomOverlay && showWithdrawInterestOverlay ? (
        <WithdrawInterestOverlay
          applicationId={applicationId}
          bookingId={id}
          goBack={goBack}
          isUpdating={isUpdating}
          setIsUpdating={setIsUpdating}
        />
      ) : null}
    </>
  )
}

export default Booking
