export const onboardingEn = {
  academy: 'Humly Training Academy',
  academyDescription: 'Please complete our introductions.',
  academyTitle: 'Introduction to Humly',
  academyInfoText:
    'Please complete our introductions in the Humly Academy. The introduction explains how we work at Humly and how you best use our tools and services. You can also find additional courses in areas that will help you succeed in your future roles. If you need to renew your safeguarding training you can do that for free through our Academy at any time.',
  academyButton: 'Launch the Academy',
  academyUrlError: 'Academy link inaccessible, please try later',
  accepted: 'Find work',
  acceptedDescription:
    'There are many great schools and nurseries posting jobs matching your profile. Once all steps of the application process are complete, you will be able to see and manage all your jobs and opportunities through this app. ',
  alevel: 'A-Level',
  approved: 'Approved',
  areYouSureDeleteFile: 'Are you sure you would like to delete this file?',
  bankAccountHolderName: 'Account name',
  bankAccountNumberError: 'Must be 8 digits',
  bankDetailsDescription: 'Please enter your full bank details below. Please make sure that you enter them correctly.',
  bankSortCode: 'Sort code',
  bankSortCodeError: 'Must be 6 digits',
  birthDate: 'Birth date',
  bookInterview: 'Book interview',
  bookInterviewCompletedInfo1:
    'During the interview, we will talk through your requirements and get to understand which roles will be suitable for you. \n\nWe will also discuss your work history over the last ten years, so please ensure you have this information ready.',
  bookInterviewCompletedTitle: 'We look forward to meeting you!',
  bookInterviewConfirmationCheckbox: 'I have booked an interview through the link. Mark this task as completed!',
  bookInterviewConfirmationInfo:
    'You will receive an email confirmation containing your booked interview time. The link to your video meeting is included in the email confirmation.',
  bookInterviewConfirmationModalTitle: 'Confirm you booked your interview',
  bookInterviewConfirmationModalDescription: 'I confirm that I have booked my interview.',
  bookInterviewConfirmedTitle: 'We look forward to meeting you!',
  bookInterviewDescription:
    "Book your interview by pressing the button below. You will be able to see our recruiters' available time slots at the website that opens and book yourself in at a time that suits you. The interview takes approximately 30-60 minutes.",
  bookInterviewInfo:
    'Couldn’t find any openings that suit your schedule?\nPlease check back in tomorrow. We regularly publish new interview slots.',
  bookInterviewTaskInfo:
    'You will receive an email confirmation containing your booked interview time. The link to your video meeting is included in the email confirmation.',
  bookInterviewTitle: 'It’s time to book your videointerview!',
  btec: 'BTEC',
  categoryTitle_profileInfo: 'My profile',
  categoryTitle_qualifications: 'Qualifications & Employment history',
  categoryTitle_references: 'References',
  categoryTitle_getToKnowHumly: 'Get to know Humly',
  categoryTitle_profileVerification: 'Verify yourself',
  categoryTitle_declarations: 'Declarations',
  categoryTitle_finalAgreements: 'Final agreements',
  categoryStatusCompleted: 'Completed',
  categoryStatusExpired: 'Expired',
  categoryStatusExpiresSoon: 'Expires soon',
  categoryStatusEnabled: '%{numberOfCompletedTasks} of %{numberOfTasks} tasks',
  childcareCheckbox: 'I don’t have any qualifications within childcare or early years.',
  childcareInfo:
    'If you are qualified within Childcare and/or Early Years, please upload copies of your qualification certificates. ',
  childcareTaskInfo:
    'I certify that I have provided truthful and accurate information. I am aware that when I have completed the task, I will no longer be able to make any further changes.',
  childcareUpload: 'I have qualification certificates to upload:',
  closeAndSubmitTask: 'Submit & Complete',
  closeTask: 'Complete task',
  companyPoliciesCheckbox: 'I have read and understood the company policies.',
  companyPoliciesDownloadButton: 'Download policies',
  companyPoliciesDownloadInfo: 'Please download and read the policies before agreeing to them below.',
  companyPoliciesInfo: 'Humly requires you to understand and agree to the following policies:',
  companyPoliciesInfoPoint1: 'Education Health Standards',
  companyPoliciesInfoPoint2: 'Equal Opportunities Policy',
  companyPoliciesInfoPoint3: 'Health and Safety Policy for Temporary Workers',
  companyPoliciesInfoPoint4: 'Complaints, Capability and Conduct Policy',
  completed: 'COMPLETED',
  completeSection: 'Complete this section',
  confirmationError:
    'We couldn’t connect to our server to save your confirmation. Please try again in a couple of minutes.\n\nIf the error persists, please, contact the office for assistance.',
  confirmationErrorTitle: 'Confirmation failed',
  contractAgreement: 'Key Information/Contract for Services',
  contractAgreementContractButton: 'Download Contract',
  contractAgreementContractCheckbox: 'I have read and understood the Contract for Services.',
  contractAgreementContractInfo:
    'This contract is an agreement between us (Humly) and yourself (candidate). Once you have downloaded your contract, on the first page, you will see it provides you with a clear understanding and overview of the contract. ',
  contractAgreementM2ContractInfo:
    'This contract is an agreement between us and yourself. This was agreed prior to us becoming Humly and your existing terms and conditions of employment remain in place. Please contact your local branch for a copy.',
  contractAgreementContractTitle: 'Contract for Services - Please download and read.',
  contractAgreementKidM2Button: 'Please contact quality@humly.co.uk for a copy.',
  contractAgreementM2ContractTitle: 'Contract of Employment',
  contractAgreementKidButton: 'Download KID',
  contractAgreementKidCheckbox: 'I have read and understood the Key Information Document.',
  contractAgreementKidInfo:
    'You must read the Key Information Document (KID) before reading The Contract for Services. The KID outlines the key elements of the contract with an example of pay illustration.',
  contractAgreementKidInfoM2Contract:
    'The KID outlines the key elements of the contract with an example of pay illustration.',
  contractAgreementTaskInfo: 'If you have any questions with regards to the documents, please contact us.',
  contractSent: 'Show contract',
  county: 'County',
  cvAlertInfo:
    "It is essential that you complete the tasks; Employment History and Qualifications. Without completing those, you won't be able to proceed in the hiring process.",
  cvAlertTitle: 'Information to you without a CV',
  cvCheckbox: 'I don’t have a CV to upload.',
  cvInfo: 'Please upload an up-to-date version of your CV. It will give us a good understanding of your profile. ',
  cvInfoFileTitle: 'I have a CV to upload:',
  cvTaskInfo:
    'I certify that I have provided truthful and accurate information. I am aware that when I have completed the task, I will no longer be able to make any further changes.',
  dbsAboutPolicyHeader:
    'The following policy applies to any candidate working in regulated activity (directly with children). ',
  dbsAboutPolicyHelperInfo:
    'If you are applying for a post which is in non-regulated activity (not directly with children) please ask for our separate non-regulated activity Rehabilitation of Offenders Policy.',
  dbsAboutPolicyInfo1:
    'We actively promote equality of opportunity for all with the right mix of talent, skills and potential and welcome applicants from a wide range of candidates, including those with a criminal record. We select all candidates for interview based on their skills, qualifications and experience.',
  dbsAboutPolicyInfo2:
    'Because the nature of the work we provide is with children, a DBS Enhanced Disclosure will be required.',
  dbsAboutPolicyInfo3:
    'We encourage all applicants to provide details of unfiltered criminal records early in the application process. We request that this information is detailed below or sent under separate confidential cover to your Humly Consultant. We guarantee that the information will only be seen by those who need to see it as part of the recruitment process.',
  dbsAboutPolicyInfo4:
    'We ensure that all those in Humly that are involved in the recruitment process have had suitable training to identify and assess the relevance and circumstances of offences. We also ensure that they all have received appropriate guidance and training in the relevant legislation relating to The employment of ex-offenders, e.g. the Rehabilitation of Offenders Act 1974.',
  dbsAboutPolicyInfo5:
    'We make every subject of a DBS Disclosure aware of the existence of the DBS Code of Practice and make a copy of this available on request. We also undertake to discuss any matter revealed in a Disclosure with the person seeking the position before withdrawing a conditional offer of employment. A criminal record will not necessarily bar you from working with us. It will depend on the nature of the position and the circumstances and background of your offences.',
  dbsAgreementsAnswer: 'Yes, I do.',
  dbsAgreementsInfo1:
    'By agreeing to this declaration, you agree to provide Humly with a copy of your DBS Enhanced Disclosure and to bring a copy of this disclosure to all work assignments. Under the School Staffing (England) Regulations 2009, we may, from time to time, need to provide a copy of your disclosure to the school or nursery you are due to work. ',
  dbsAgreementsInfo2:
    'You also agree to make Humly aware of any investigation or prosecution relating to a criminal act which could lead to a conviction after undertaking your most recent DBS/Update Service check.',
  dbsAgreementsQuestion:
    'Do you authorise Humly to carry out online Status Checks using the DBS Update Service as required?',
  dbsCertificate:
    'Please upload the entire copy of your Enhanced Child Workforce DBS. Including your Name, Address, and Certificate number.',
  dbsDeclaration: 'Disclosure and Barring Service',
  dbsDeclarationDescription:
    'This policy applies to any candidate working in regulated activity (directly with children).',
  dbsDescription:
    'If you have an Enhanced Child Workforce DBS that is registered to the Update Service then please upload the entire copy of your DBS including your Name, Address and Certificate Number.',
  dbsTitle: 'DBS',
  dbsDeclarationService: 'The Disclosure and Barring Service',
  dbsDeclarationServiceHelperInfo: `We cannot accept voluntary DBS's as you will be carrying out paid work and not unpaid voluntary work.`,
  dbsDeclarationServiceInfo1: `Humly uses the Disclosure and Barring Service to assess all applicants' suitability for any position that involves working with children. We request that you disclose any unfiltered information about spent and unspent convictions, cautions or reprimands at registration.`,
  dbsDeclarationServiceInfo2: `We also request you complete a new Enhanced Child Workforce DBS application at registration. If you already have this type of DBS and it's registered to the Update Service, we are able to utilise this against your file.`,
  dbsReadPolicy:
    'It is important that you read the Enhanced DBS Check Privacy Policy for applicants before submitting an application to the DBS. It describes how DBS will process your personal data, and the options available to you for submitting your application.',
  dbsReadPolicyAnswer: 'I confirm that I have read the policy.',
  dbsReadPolicyButton: 'Open DBS Privacy Policies',
  dbsReadPolicyQuestion: 'Have you read the Enhanced DBS Check Privacy Policy for applicants?',
  degreeCertificate: 'Degree Certificate',
  delete: 'Delete',
  deleteFile: 'Delete file',
  digitalRightToWork: 'Digital Right to Work and ID verification',
  digitalRightToWorkUrlError:
    'We couldn’t connect to YOTI. Please try again in a couple of minutes.\n\nIf the error persists, please, contact the office for assistance.',
  digitalRightToWorkUrlErrorTitle: 'Problem connecting to YOTI',
  digitalRightToWorkButton: 'Open YOTI',
  discloser: 'Discloser',
  disqualificationDeclaration: 'Disqualification Declaration',
  disqualificationDeclarationAbout: 'About the Disqualification Declaration',
  disqualificationDeclarationConvictions:
    'Individuals seeking certain work with children and childcare work are required to disclose their own spent and unspent convictions. However, in completing this form you are not required to: provide information about any protected (or filtered) offences; disclose any information about spent convictions of any third party.\n\nIf you are disqualified we will not be able to place you/supply you into a role that involves relevant childcare work. However, as set out in the DfE Guidance you may be able to apply to Ofsted for a waiver of disqualification and you should contact Ofsted directly for details of the application process.',
  disqualificationDeclarationDescription: 'Complete the Childcare Disqualification Staff Declaration',
  disqualificationDeclarationPlaceholder: 'Please describe...',
  disqualificationDeclarationGuidance1:
    'In October 2014, the Department for Education (DfE) published new guidance titled “Keeping Children safe in education: childcare disqualification requirements – supplementary advice”.',
  disqualificationDeclarationGuidance2:
    'This guidance was published as a supplement to the statutory “Keeping Children Safe in Education” guidance (issued in April 2014) and directed schools on additional checks they are required to carry out as part of their recruitment processes to comply with childcare legislation (the Childcare Act 2006 and the Childcare (Disqualification) Regulations 2009).',
  disqualificationDeclarationGuidance3:
    'The guidance sets out the circumstances in which individuals are disqualified from undertaking particular childcare work (relevant childcare work). As part of our safeguarding checks, we are required to check whether any candidates seeking work involving relevant childcare work are ‘disqualified’ from carrying out that type of work.',
  disqualificationDeclarationGuidance4:
    'Individuals may either be disqualified directly because they have been convicted of a relevant offence or are subject to a relevant order or they may be disqualified by association because they live in the same household where someone lives or is employed who meets the disqualification criteria.',
  disqualificationDeclarationGuidanceAlert:
    'As a result of this new guidance, we require all our candidates to read, understand and complete the following form. This needs to be completed annually.',
  disqualificationDeclarationNoteInfo:
    'You have answered Yes, to the above question. Please provide further information:',
  disqualificationDeclarationNotifyHumly:
    'I will notify Humly immediately if any of the above changes and I confirm that the information I have given in this Declaration is true and accurate.',
  disqualificationDeclarationOpenGuidanceButton: 'Open guidance',
  disqualificationDeclarationOpenGuidanceHelperInfo: 'The guidance will open in your browser.',
  disqualificationDeclarationOpenGuidanceInfo:
    'Please click below to access the “Department for\nEducation: Disqualification under the Childcare Act 2006\n– statutory guidance for schools”',
  disqualificationDeclarationQuestion:
    'Are you Disqualified on any of the grounds set out in the Department for Education Guidance?',
  duties: 'Duties',
  emergencyContactDetails: 'Emergency Contact Details',
  emergencyContactDetailsDescription: 'Add an emergency contact who we can reach out to if needed',
  emergencyContactDetailsInfo: 'In case of an emergency, please enter your next of kin details.',
  emergencyContactName: 'Contact full name',
  emergencyContactPhone: 'Contact phone number',
  emergencyContactRelationship: 'Relationship to you',
  emergencyNameError: 'Enter full name',
  emergencyRelationError: 'Enter their relation to you, e.g. parent, partner, etc.',
  employeeStatement: 'Employee Statement',
  employeeStatementDescription:
    'To calculate and deduct the right amount of tax from your pay, we will need information about any employment since the 6th of April. Please read the following statements carefully and give your answers. You need to select only one of the three following statements:',
  employeeStatementInfo: `If you have a P45 from a previous employer with a leaving date AFTER last April 6th, please send it to us to continue your tax code without interruption. If you do not send us a P45, you may be taxed incorrectly for a few weeks while HMRC issue a new tax code - any over/under tax payments rectifies in the subsequent weeks' pay.`,
  employer: 'Employer',
  employmentHistory: 'Employment history',
  employmentHistoryTitle: 'Please complete this section before your interview.',
  endDate: 'End date:',
  endDateOngoing: 'Ongoing/Current employment',
  enterDuties: 'Duties',
  enterEmployer: 'Employer',
  enterLeavingReason: 'Reason for leaving',
  enterRole: 'Role',
  enterSalutation: '(Optional) Enter preferred nick name',
  enterValidEmail: 'Please enter a valid email address',
  errorLettersOnly: 'Only letters (A-Z), space, and hyphen (-) allowed',
  errorLettersOnlyStrict: 'Only letters (A-Z) allowed',
  experienceDelete: 'Delete employment',
  experienceDeleteConfirmationTitle: 'Delete this employment?',
  experienceDeleteConfirmationInfo:
    'You cannot undo this action. The information will be permanently deleted. Are you sure you want to delete this employment?',
  experienceFormAlertTitle: 'Edits disabled',
  experienceFormAlertInfo:
    'This information was locked when you submitted this task. Contact us if you need to make changes.',
  experienceFormTitle: 'Add employment history',
  experienceSchoolButton: 'Add employment history',
  experienceSchoolLabel:
    'Please provide details of your employment history. This will be used to build your profile that we can send to  clients for potential work. If applicable we expect to see at least your last five years of employment. Thank you!',
  expired: 'EXPIRED %{expireDate}',
  extraJob: 'Extra job',
  firstName: 'First name',
  fileError:
    'We couldn’t connect to our server to fetch the document. Please try again in a couple of minutes.\n\nIf the error persists, please, contact the office for assistance.',
  fileErrorTitle: 'Problem connecting to server',
  formDeleteError:
    'We couldn’t connect to our server to delete this information. Please try again in a couple of minutes.\n\nIf the error persists, please, contact the office for assistance.',
  formDeleteErrorTitle: 'Deletion failed',
  formSaveError:
    'We couldn’t connect to our server to save this information. Please try again in a couple of minutes.\n\nIf the error persists, please, contact the office for assistance.',
  formSaveErrorTitle: 'Saving failed',
  formNumber: 'Must be a valid number',
  fullTime: 'Full time',
  goBack: 'Go back',
  gsa: 'GSA',
  hasHealthIssues:
    'Do you have any health issues or a disability relevant which may make it difficult for you to carry out functions which are essential for the role you seek?',
  hiringProcess: 'My hiring process',
  houseNumberAndStreet: 'House number & Street name',
  inductionCertificate: 'Induction Certificate',
  interviewAlertInfo:
    'Your recruiter will mark this task as completed if you proceed to the next steps after the interview.',
  interviewCompletedInfo:
    'We believe you would make a great educator within Humly. Before you can get out to work you need to clear any remaining tasks.\nOur Talent Success team will do the final checks as soon as you have completed all tasks and then you are ready for work! ',
  interviewCompletedTitle: 'Thank you for a nice interview!',
  interviewInfo:
    'The interview takes place through an online video meeting. You find the link to the video meeting in the confirmation email you received when booking your interview.',
  interviewTip1: 'Be ready in good time! That way you will have time to sort out any technical issues.',
  interviewTip2: 'Find yourself a calm and safe location where you and the recruiter can talk without being disturbed.',
  interviewTip3:
    'Our recruiters are on a tight schedule. If you experience any issues when attending your interview you should call us as soon as possible to try and sort things out.',
  interviewTip4: 'Dare to talk good about yourself! What makes you the right person for work in schools or nurseries?',
  interviewTip5:
    'Please feel free to ask questions to your recruiter. The interview is intended for us to get to know each other.',
  interviewTip6:
    'Please consider what type of jobs are you interested in and how much work you are looking for. For example, are you looking for full-time weeks in one setting or day-to-day work? What type of roles? ',
  interviewTip7:
    'It’s completely natural to be a bit nervous when having your interview. Keep in mind that the purpose of the interview is for us to get to know each other. ',
  interviewTipTitle: 'Tips for your interview:',
  interview: 'Interview',
  introductionAlertInfo:
    'Want to learn about working at Humly? \nCheck out our introduction. You find it, and much more, in our Academy through the app menu.',
  introductionAlertTitle: 'Tip',
  leavingReason: 'Reason for leaving',
  legislationError:
    'An error occurred when trying to access the legislation. Please try again in a couple of minutes or visit gov.uk to find it yourself.\n\nIf the error persists, please, contact the office for assistance.',
  legislationErrorTitle: 'Failed to access the legislation',
  lockedTask_completeInterview: 'Unlocked if you proceed from the interview',
  lockedTask_completeInterviewModalInfo: `We will unlock this task after meeting you in the interview.\n\nHaven't booked your interview yet? Find out how in the "Get to know Humly" section.`,
  lockedTask_modalTitle: 'This task is locked',
  lockedTask_qualificationsAndExperience: 'Unlocked after pre-screen of Qualifications & Employments',
  lockedTask_qualificationsAndExperienceModalInfo:
    'Please complete the tasks in the Qualifications & Employment history section first. Those tasks are required to conduct our initial pre-screen of your educator profile before unlocking the next steps.\n\nThe more tasks you complete, the easier it is for us at Humly to form a correct understanding of You and your educator profile.',
  masters_degree: 'Masters Degree',
  medicalDeclaration: 'Medical Declaration',
  medicalDeclarationDescription: 'Complete this questionnaire after your interview',
  medicalQuestionnaireAbout:
    'Prior to sending you out to a work assignment we are required to assess your ability to undertake functions which are intrinsic to the temporary supply teaching or childcare role. This is stipulated in The Education (Health Standards) (England) Regulations 2003. We are also required to re-validate this ability every 12 months.',
  medicalQuestionnaireAboutTitle: 'About the Medical declaration',
  medicalQuestionnaireInfo:
    'As the work will include an element of sole responsibility for groups of children, for the Health & Safety of our candidates and the children in their care it is our duty to ensure that our candidate’s health/medical fitness would not put themselves or the children at risk in any way whilst in a work assignment at a school or nursery.',
  medicalQuestionnaireInfo2:
    'We may ask you to seek an Occupational Health Specialist or GP to confirm you are fit to teach/work in childcare and potentially from time to time be solely responsible for groups of children.',
  medicalQuestionnaireInfo3:
    'Anything disclosed to us in this section will be treated in the strictest of confidence. Only basic details will be disclosed to the schools or nurseries in order to fulfil legal obligations. This if reasonable adjustments need to be made to your working environment, or if relevant for Health & Safety reasons, i.e. disability or allergy.',
  medicalQuestionnaireInfo4:
    'For full details of how we process your personal data, please visit humly.co.uk/privacy-policy to review our Privacy Policy.',
  medicalQuestionnaireMedicalNotes: 'Shared Medical Notes:',
  medicalQuestionnaireMedicalNotesInfo:
    'The above information is made available to schools or nurseries where you undertake an assignment. If you would like to change this information please get in contact with us.',
  medicalQuestionnaireNoteAlertInfo:
    'You are required to submit a new medical questionnaire every 12 months or at any time if your medical situation would change. ',
  medicalQuestionnaireNoteAlertTitle: 'Note!',
  medicalQuestionnaireQ1A1: 'Yes, I consider myself to be physically & mentally fit.',
  medicalQuestionnaireQ1A2: 'No, I do not consider myself to be physically & mentally fit.',
  medicalQuestionnaireQ2A1: 'Yes, I have a health issue or disability.',
  medicalQuestionnaireQ2A2: 'No, I do not have any health issues or disabilities.',
  medicalQuestionnaireQuestion1:
    '1. Do you consider yourself to be physically and mentally fit to teach or take sole responsibility for children?',
  medicalQuestionnaireQuestion2:
    '2. Do you have any health issues or a disability relevant which may make it difficult for you to carry out functions which are essential for the role you seek?',
  medicalQuestionnaireQuestion2b:
    '2b. What are your needs in terms of reasonable adjustments in order to access this recruitment service?',
  medicalQuestionnaireQuestion2bPlaceholder: 'Enter additional health information here...',
  medicalQuestionnaireStartButton: 'Start questionnaire',
  medicalQuestionnaireStatus: 'Your current medical questionnaire expires: ',
  medicalQuestionnaireStatusNew: 'You have not submitted a medical declaration within the last 12 months.',
  mentallyAndphysicallyFit:
    'Do you consider yourself to be physically and mentally fit to teach or take sole responsibility for children?',
  nameError: 'Enter full name',
  nationalInsuranceNumber: 'National insurance number',

  nationality: 'Nationality',
  new: 'Application received',
  nextState: 'Next',
  niNumberAcceptedDoc1: 'Payslip, dated within the last 3 months.',
  niNumberAcceptedDoc2: 'HMRC Letter',
  niNumberAcceptedDoc3: 'Tax, Pension, or Benefits Letter',
  niNumberAcceptedDoc4: 'P60',
  niNumberInputInfo: 'For example, ‘QQ 12 34 56 C’.',
  niNumberInputLabel: 'National Insurance number',
  niNumberMoreButton: 'More information about NI number',
  niNumberSubHeader: 'We accept the following types of documents for verifying your National Insurance number:',
  niNumberSubInfo: 'See the list at the bottom of the page for all types of documents we accept.',
  niNumberTaskDescription:
    'Please input your National Insurance number and upload a clear picture of, for example, your NI card/letter or a recent payslip to verify it.',
  niNumberTaskInfo:
    'I certify that I have provided truthful and accurate information. I am aware that when I have completed the task, I will no longer be able to make any further changes.',
  niNumberTitle1: '1. Fill in your National Insurance number',
  niNumberTitle2: '2. Upload a picture for verification',
  niNumberDescription: 'Please upload a clear picture of your NI Card/Letter or a recent payslip.',
  niNumberTitle: 'NI Number',
  notCompleted: 'NOT COMPLETED',
  note: 'Note!',
  nvq: 'NVQ',
  other: 'Other',
  otherDocumentsCheckbox: "I don't have any additional documents to upload",
  otherDocumentsDescription:
    'If you have any other documents we may require that don’t match the categories, then please upload them here. If you don’t have any additional documentation to upload, you can tick that option to enable you to complete the task. ',
  otherDocumentsSubinfo:
    'I certify that I have provided truthful and accurate information. I am aware that when I have completed the task, I will no longer be able to make any further changes.',
  partTime: 'Part time',
  path: 'Your personal profile will be used on your CV and for profiles we send to clients on your behalf',
  payrollDetails: 'Payroll Details',
  personalDetails: 'Personal details',
  personalProfile: 'Personal profile',
  personalProfileInfo:
    'This profile biography will be used on your educator profile and a CV we create for you. It will be shared with potential employers on your behalf. Please describe yourself both personally and as an educator.',
  personalProfileInfo2: 'We are happy to assist you in optimising this biography during or after your interview. ',
  phd: 'PhD',
  phoneNumber: 'Telephone',
  plan: 'Plan %{number}',
  policeCheckCheckbox: 'I have not been living or working overseas, and I don’t have any overseas police checks.',
  policeCheckDocumentNeeded: 'Then we will need a translated copy of any overseas police checks from those countries.',
  policeCheckLivedOutsideUk:
    'Have you been living or working overseas for more than 6 months within the last 5 years? ',
  policeCheckUploadFile: 'I have overseas police checks:',
  policyAgreements: 'Company Policies',
  post_graduate_degree: 'Post Graduate Degree',
  post_interview: 'Post-interview tasks',
  post_interviewButton: 'Post-Interview Tasks',
  post_interviewDescription:
    'We have to carry out quite a few checks as you will be working with children. These tasks will open up after we have met for an interview. ',
  postgraduateLoan: 'Postgraduate Loan',
  postgraduateLoanDescription: 'Please answer the following to define your current Postgraduate Loan status.',
  postgraduateLoanUnpaid: 'Do you have a Postgraduate Loan which is not fully repaid?',
  postInterviewTasks: 'Post-interview tasks',
  postInterviewTasksDescription: 'This is the information that we need from you before we can find you work',
  pre_interview: 'Pre-interview tasks',
  pre_interviewButton: 'Pre-Interview Tasks',
  pre_interviewDescriptionDone: 'Thank you for completing the pre-interview tasks.',
  preinterviewTasks: 'Preinterview tasks',
  preinterviewTasksDescription: 'Please complete the sections below before your interview.',
  prevState: 'Previous',
  profileTaskStatusCompleted: 'Completed',
  profileTaskStatusCompletedDescription: 'All done here!',
  profileTaskStatusExpired: 'Update required',
  profileTaskStatusExpiredDescription: 'Update required!',
  profileTaskStatusExpiredShort: 'Expired',
  profileTaskStatusExpiresSoon: 'Expires soon',
  profileTaskStatusToDo: 'To Do',
  profileTaskStatusToDoDescription: '%{timeToComplete} to complete',
  progressTasks: '%{numberOfTasks} tasks',
  progressDone: '%{numberOfTasks} Done',
  qualifications: 'Qualifications',
  qualificationAlertTitle: 'Edits disabled',
  qualificationAlertInfo:
    'This information was locked when you submitted this task. Contact us if you need to make changes.',
  qualificationCheckbox: "I've added all relevant qualifications",
  qualificationDeleteButton: 'Delete qualification',
  qualificationsDescription:
    'Please provide details of all your qualifications. This will be used to build your profile that we can send to  clients for potential work.',
  qualificationFormCheckboxOngoing: 'Ongoing/Current qualification',
  qualificationFormDeleteInfo:
    'You cannot undo this action. The information will be permanently deleted. Are you sure you want to delete this qualification?',
  qualificationFormDeleteTile: 'Delete this qualification?',
  qualificationFormInstitutionLabel: 'Name of Institution',
  qualificationFormSubjectLabel: 'Subjects & Results',
  qualificationFormTitle: 'Add qualification',
  qualificationFormTypesLabel: 'Qualification type',
  qualificationNewButton: 'Add qualification',
  qualificationTaskInfo:
    'I certify that I have provided truthful and accurate information. I am aware that when I have completed the task, I will no longer be able to make any further changes.',
  qualificationType: 'Qualification type',
  quitFormNO: 'Stay on page',
  quitFormOK: 'Leave page',
  quitFormTitle: 'Are you sure you want to leave?',
  quitFormTitleDescription:
    "Your result won't be saved unless you complete the form. Do you really want to cancel and go back?",
  redoTask: 'Redo the task',
  refereeDetails: 'Referee details:',
  refereeEmail: 'Referee Email',
  refereeName: 'Full name of Referee',
  refereePhone: 'Referee phone number',
  refereeReason: 'Reason for leaving ',
  referenceAddButton: 'Add reference',
  referenceDeleteModalTitle: 'Delete this reference?',
  referenceDeleteModalInfo:
    'You cannot undo this action. The information will be permanently deleted. Are you sure you want to delete this reference?',
  referenceEmployer: 'Employer Organisation/Company name',
  referenceEndDate: 'End date at this role',
  referenceFormTitle: 'Add new reference',
  referenceJobTitle: 'Your job title at this job',
  referenceJobTitleLabel: 'Your own role at this job: ',
  referenceOccupation: 'Referee Occupation',
  references: 'References',
  referencesCount_0: 'First reference',
  referencesCount_1: 'Second reference',
  referencesCount_2: 'Third reference',
  referencesDescription: 'Add two references. Complete this section before your interview',
  referencesFormDescription:
    'One of the referees should be your most recent employer. Humly is required to gather at least two references as part of the vetting process. One of them must be your most recent employer and each reference should be someone who held a senior or management position while you were working together. We will contact your references once we have interviewed you.',
  referencesInfo:
    'Humly is required to gather at least two references as part of the vetting process. One of them must be your most recent employer. Each reference should be someone who held a senior or management position while you were working together.',
  referencesInfo2: 'We will contact your references once we have interviewed you.',
  required: 'Required',
  requiredInfo: 'Required info',
  requiredTasks: 'Required tasks',
  role: 'Role',
  roleEndDate: 'End date at this Role',
  roleStartDate: 'Start date at this Role',
  rtwCheckboxLabel: 'I’m a UK or Irish citizen.',
  rtwProvideInfo:
    'If you are not a UK or Irish citizen, we will need you to provide your right to work in the UK by providing a share code confirmation.',
  rtwReadMore: 'Read more here if needed: www.gov.uk/prove-right-to-work',
  rtwUploadFile: 'I have a share code confirmation proving my right to work in the UK:',
  safeguarding: 'Safeguarding Declaration',
  safeguardingDescription: 'Complete the Safeguarding Declarations',
  safeguardingTrainingUrlError: 'Safeguarding training link inaccessible',
  safeguardingInfo1:
    'Humly requires you to understand the latest safeguarding guidance and know how best to protect the children in your care. We also believe that it is essential that you know how to prevent allegations from being made against you.',
  safeguardingInfo2:
    'To ensure that you understand your responsibilities and are fully informed of the potential consequences of your actions and behaviour, we will ask as part of registration and every following year that you:',
  safeguardingInfo2a: 'Thoroughly read and understand our Safeguarding Policy',
  safeguardingInfo2b: 'Read Part 1 and Annex B of Keeping Children Safe in Education',
  safeguardingInfo2c:
    'Confirm you have undertaken Safeguarding training in the last 12 months (We provide free training if you haven’t)',
  safeguardingInfo3:
    "We insist you avoid any conduct that would lead a reasonable person to question your motivation and intentions toward children, particularly any unnecessary physical contact. You should make yourself aware of our client schools/nurseries' staff Behaviour Policy/Code of Conduct, Child Protection Policy, and Health & Safety Policy where you are booked for work.",
  safeguardingInfoAlert:
    'Humly would like to make you aware that breaches of the law and the professional guidelines above could result in the company no longer offering you work. If we remove you from our database due to a proven safeguarding concern, it becomes our legal duty to forward our findings to the DBS and/or TRA. Proven safeguarding concerns can result in criminal action, and/or other proceedings including barring by the Disclosure & Barring Service (DBS), or for acts of serious misconduct, prohibition from teaching by the Teaching Regulations Agency (TRA).',
  safeguardingPolicy1Title: '1. Humly Safeguarding Policy',
  safeguardingPolicy1Info:
    'Please thoroughly read our latest Safeguarding Policy and show your agreement by clicking the box below.',
  safeguardingPolicy1Button: 'Download Safeguarding Policy',
  safeguardingPolicy1Checkbox: 'I have read, understood and agree to the Humly Safeguarding Policy.',
  safeguardingPolicy2Title: '2. Keeping Children Safe in Education',
  safeguardingPolicy2Button: 'Access Legislation',
  safeguardingPolicy2Info:
    'You are required to read and understand section 1 of the Keeping Children Safe in Education legislation. Access the legislation through the button below and show your agreement by ticking the box below.',
  safeguardingPolicy2Checkbox:
    'I have read and understood section 1 of the Keeping Children Safe in Education legislation.',
  safeguardingTrainingTitle: '3. Safeguarding Training',
  safeguardingTrainingTipInfo: 'If you haven’t, we can provide you with free online training through our Academy.',
  safeguardingTrainingQuestion1: 'Have you undertaken safeguarding training in the past 12 months?',
  safeguardingTrainingQuestion2: 'When did you complete your last safeguarding training?',
  safeguardingTrainingCertificateUpload: 'Upload your valid safeguarding certificate:',
  safeguardingTrainingDateSelect: 'Select Date',
  safeguardingTrainingDateInfo: 'You completed training through our academy:',
  safeguardingTrainingCertificateUploadInfo:
    'We require a valid safeguarding certificate to validate that you have completed training. If you don’t have a certificate, please select “No” and we can provide you with free online training through our Academy.',
  safeguardingTrainingAcademyInfo:
    'For you that haven’t had recent training, we provide free online training through our Academy:',
  safeguardingTrainingAcademyOpen: 'Open Safeguarding Training',
  safeguardingTrainingAcademyTime:
    'The training takes approximately two hours to finish. You don’t have to complete it all in one session, we will keep track of your progress. ',
  safeguardingTrainingAcademyAlertTitle: 'Note',
  safeguardingTrainingAcademyAlertInfo:
    'After you have completed the training, please return here to mark the whole task as complete. The button below will be enabled when you have completed the training.',
  saved: 'Changes saved successfully!',
  selectNationality: 'Select nationality',
  selectTitle: 'Select the title you wish to go by',
  selectWorkload: 'Workload',
  serverError:
    'We couldn’t connect to our server to submit this task. Please try again in a couple of minutes.\n\nIf the error persists, please, contact the office for assistance.',
  serverErrorTitle: 'Problem connecting to server',
  startDate: 'Start date:',
  stateContactTitle: 'Any questions? Don’t hesitate to get in touch!',
  stepsDescription: 'Steps to complete before getting started:',
  streetAddress: 'Street address',
  studentLoan: 'Student Loan',
  studentLoanDescription: 'Please answer the following to define your current student loan status.',
  studentLoanInfo: 'To avoid repaying more than you need, read the information below and select the correct plan.',
  studentLoanInfoPlan1: 'You have Plan 1 if any of the following apply:',
  studentLoanInfoPlan1Option1: 'You lived in Northern Ireland when you started your course.',
  studentLoanInfoPlan1Option2: 'You lived in England or Wales and started your course before 1 September 2012.',
  studentLoanInfoPlan2: 'You have a Plan 2 if:',
  studentLoanInfoPlan2Option1: 'You lived in England or Wales and started your course on or after 1 September 2012.',
  studentLoanInfoPlan4: 'You have a Plan 4 if:',
  studentLoanInfoPlan4Option1:
    'You lived in Scotland and applied through the Students Award Agency Scotland (SAAS) when you started your course.',
  studentLoanPlanLabel: 'Please select the plan that applies to you:',
  studentLoanUnpaid: 'Do you have a Student Loan which is not fully repaid?',
  submit: 'Submit',
  submitAndComplete: 'Submit & Complete',
  submitDetails: 'Submit details',
  submitDetailsDescription:
    'By submitting this information, you agree that all money due from work already completed or for any future work will be processed via our PAYE payroll and paid into the bank account detailed above.',
  summerJob: 'Summer job',
  surname: 'Surname',
  taskConfirmation: "I've added all relevant details of my employment history.",
  taskConfirmationExperience: "I've added all relevant details of my employment history.",
  taskConfirmationTitle_medicalQuestionnaire: 'Questionnaire submitted!',
  taskConfirmationInfo_medicalQuestionnaire:
    'Thank you for your submission. Your answers have been passed to our ongoing compliance team for review. They will get back to you soon.',
  taskInfoDeclaraction:
    'I certify that I have provided truthful and accurate information. I am aware that when I have completed the task, I will no longer be able to make any further changes.',
  taskTitle_addressVerification: 'Verify Address',
  taskTitle_bookInterview: 'Book Interview',
  taskTitle_childCareCertificate: 'Childcare/EY Certificates',
  taskTitle_contract: 'Contract',
  taskTitle_contractAgreement: 'Humly KID & Contract for Services',
  taskTitle_cvDocument: 'CV',
  taskTitle_dbsCertificate: 'DBS Certificate',
  taskTitle_dbsDeclaration: 'DBS Authorization',
  taskTitle_disqualificationDeclaration: 'Disqualification Declaration',
  taskTitle_emergencyContact: 'Emergency Contact Details',
  taskTitle_employmentHistory: 'Employment History',
  taskTitle_highSchoolEducation: 'HighSchool Education',
  taskTitle_humlyAcademy: 'Academy Introduction',
  taskTitle_idVerification: 'Id Verification',
  taskTitle_interview: 'Interview',
  taskTitle_medicalQuestionnaire: 'Medical Declaration',
  taskTitle_niNumber: 'Verify NI Number',
  taskTitle_otherDocuments: 'Other Support Documents',
  taskTitle_payrollDetails: 'Payroll Details',
  taskTitle_personalDetails: 'Personal Details',
  taskTitle_personalProfile: 'Profile Biography',
  taskTitle_policies: 'Policies',
  taskTitle_policyAgreements: 'Company Policies',
  taskTitle_proofOfEducations: 'Proof Of Educations',
  taskTitle_proofOfPoliceCheck: 'Overseas Police Check',
  taskTitle_proofOfPoliceReport: 'Proof Of Police Report',
  taskTitle_proofOfRightToWork: 'Overseas Proof of Right to Work',
  taskTitle_qualifications: 'Qualifications',
  taskTitle_references: 'References',
  taskTitle_safeguardingDeclarations: 'Safeguarding Declaration',
  taskTitle_teacherReferenceNumber: 'Teacher Reference Number',
  taskTitle_teachingCertificates: 'Teaching Certificates',
  taskTitle_universityEducation: 'University Education',
  taskTitle_yotiStatus: 'ID verification',
  tasksCompleted: 'Completed',
  tasksTodo: 'To do',
  teacherReferenceNumber: 'Teacher Reference Number',
  teacherReferenceNumberDescription:
    'If you are a qualified teacher please upload proof of your Teacher Reference Number which is usually a letter from the DfE.',
  teacherReferenceNumberCheckbox: 'I’m not a qualified teacher and don’t have a teacher reference number.',
  teacherReferenceNumberInfo:
    'If you are qualified teacher, please upload proof of your Teacher Reference number. This is usually a letter from the Department for Education.',
  teacherReferenceNumberTaskInfo:
    'I certify that I have provided truthful and accurate information. I am aware that when I have completed the task, I will no longer be able to make any further changes.',
  teacherReferenceNumberUpload: 'I have a teacher reference number:',
  teachingCertificatesCheckbox: 'I’m not a qualified teacher and I don’t have any teaching certificates.',
  teachingCertificatesInfo:
    'If you are a qualified teacher, please upload copies of your teaching qualification, your QTS certificate, and your Induction certificate in their respective sections.',
  teachingCertificatesSection1: 'Teaching qualification',
  teachingCertificatesSection2: 'QTS Certificate',
  teachingCertificatesSection3: 'Induction Certificate',
  teachingCertificatesTaskInfo:
    'I certify that I have provided truthful and accurate information. I am aware that when I have completed the task, I will no longer be able to make any further changes.',
  teachingCertificatesTip:
    'Tip: You can find copies of your QTS & Induction certificates at www.gov.uk/government/collections/qualified-teacher-status-qts',
  teachingCertificatesUpload: 'I am a qualified teacher:',
  townCity: 'Town/city',
  trainingError:
    'An error occurred when trying to access the training. Please try again in a couple of minutes.\n\nIf the error persists, please, contact the office for assistance.',
  trainingErrorTitle: 'Failed to access the training',
  ukPostgraduateLeft: 'Did you complete or leave your Postgraduate studies before the 6th of April?',
  ukTaxCodeOptionsA:
    "This is my first job since the 6th of April, and I have not received taxable Jobseeker's Allowance, Employment and Support Allowance, taxable Incapacity Benefit, State or Occupational Pension.",
  ukTaxCodeOptionsB:
    "This is now my only job, but since the 6th of April, I have had another job or received taxable Jobseeker's Allowance, Employment and Support Allowance or taxable Incapacity Benefit. I do not receive a State or Occupational Pension.",
  ukTaxCodeOptionsC: 'As well as my new job, I have another job or receive a State or Occupational Pension.',
  ukUniversityLeft: 'Did you complete or leave your studies before the 6th of April?',
  undergraduate_degree: 'Undergraduate Degree',
  uploadDocuments: 'Upload Documents',
  uploadDocumentsDescription: 'As part of your application process we need to see copies of certain documents',
  uploadFile: 'Upload file',
  uploadFiles: 'Upload files',
  verifyAddressAcceptedDocuments1: 'Utility bill, dated within the last 3 months.',
  verifyAddressAcceptedDocuments2: 'Bank statement, dated within the last 3 months.',
  verifyAddressAcceptedDocuments3: 'P45, dated within the last 12 months.',
  verifyAddressAcceptedDocuments4: 'P60, dated within the last 12 months.',
  verifyAddressAcceptedDocuments5: 'Driving License',
  verifyAddressAcceptedDocumentsHeader: 'We accept the following types of documents for verifying your address:',
  verifyAddressMoreInfo: 'More information about verifying address',
  verifyAddressUploadPicture:
    'Please upload a clear picture of, for example, a recent utility bill or bank statement to verify your address. We require two proofs of your address.',
  verifyAddressUploadPictureAdditionalInfo:
    'See the list at the bottom of the page for the types of documents we accept.',
  verifyAddressRegisteredAddress: 'Your registered address is:',
  verifyAddressUploadPictureHeader: 'Upload two proofs of your address',
  warning: 'UPDATE WITHIN %{expireDate} DAYS',
  workload: 'Work load',
  yotiCompleted: 'Thank you for verifying your identity!',
  yotiHint:
    'If you are unsure of how to use the YOTI service or do not wish to use this service, then please contact the office for guidance.',
  yotiIdentity:
    'As part of the application process, Humly will make thorough checks to verify your identity. The checks include name, date of birth, address and photographic identity through an Identity Digital Service Provider called YOTI. ',
  yotiUpload:
    'YOTI makes it safer for individuals to prove who they are, for example by uploading your passport and driving license.',
  yotiResults:
    'Your result will automatically be sent to Humly after completing the checks and this task will be marked as completed.\nNote that it can take up to 15 minutes before our system receives the result and updates the task status.',
}
