import { View, Text, StyleSheet } from 'react-native'
import React from 'react'
import moment from 'moment'

import DayFrame from 'src/components/DayFrame'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import { OfferHeaderProps } from 'src/scenes/Main/Jobs/Details/components/JobDetails/types'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'

const OfferHeader = ({ startDate, title, hours, details, isMultiday, scrollToBookingList }: OfferHeaderProps) => (
  <View style={styles.container}>
    <DayFrame
      day={moment(startDate).format('D') || ''}
      month={moment(startDate).format('MMM') || ''}
      isMultiday={isMultiday}
    />
    <View style={styles.info}>
      <Text style={styles.title}>
        {/* eslint-disable-next-line react-native/no-raw-text */}
        <Text style={StyleSheet.flatten(!isMultiday && styles.textCapitalize)}>{title}</Text> • {hours}
      </Text>
      <View style={styles.detailsContainer}>
        <Text style={StyleSheet.flatten([styles.subTitle, isMultiday && styles.subTitleSpacing])}>{details}</Text>
        {isMultiday ? (
          <ButtonNew
            onPress={scrollToBookingList}
            size={buttonSize.sm}
            testID="show-more-bookings"
            title={translate(translations.more)}
            variant={buttonVariants.textDefault}
          />
        ) : null}
      </View>
    </View>
  </View>
)

export default OfferHeader
