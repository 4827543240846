import React, { useEffect } from 'react'
import { View, Text, Linking, ScrollView } from 'react-native'
import moment from 'moment'
import { useNavigation } from '@react-navigation/native'

import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import { NotificationsScreensProps } from 'src/utils/types/navigationTypes'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import { navigateToAppScreenWithoutHistory } from 'src/utils/navigateToScreen'
import { renderHeaderButton } from 'src/utils/headerConfig/renderHeaderButton'
import { campaignRedirects } from 'src/scenes/Main/Inbox/CampaignDetails/utils'

const CampaignDetails = ({ route }: NotificationsScreensProps<'CampaignDetails'>) => {
  const navigation = useNavigation<NotificationsScreensProps<'CampaignDetails'>['navigation']>()

  const { content, createdAt, externalLink, openView, title, isBackToNotifications } = route.params

  const date = moment(createdAt).format('YYYY-MM-DD')

  useEffect(() => {
    if (isBackToNotifications) {
      navigation.setOptions({
        headerLeft: renderHeaderButton('back', () =>
          navigateToAppScreenWithoutHistory(['MainTabNavigator', 'Notifications', 'NotificationsList']),
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ScrollView contentContainerStyle={styles.container} testID="scrollView">
      <View>
        <View style={styles.header}>
          <Text style={styles.info}>{translate(translations.notificationsCampaignTitle)}</Text>
          <Text style={styles.info}>{date}</Text>
        </View>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.content}>{content}</Text>
        {externalLink && (
          <ButtonNew
            onPress={() => Linking.openURL(externalLink)}
            size={buttonSize.lg}
            title={translate(translations.notificationsCampaignLink)}
            variant={buttonVariants.containedDefault}
          />
        )}
        {openView && (
          <ButtonNew
            onPress={campaignRedirects[openView].action}
            size={buttonSize.lg}
            title={campaignRedirects[openView].text}
            variant={buttonVariants.containedDefault}
          />
        )}
      </View>
    </ScrollView>
  )
}

export default CampaignDetails
