const FirebaseAdapter = () => ({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deleteToken: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getInitialNotification: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getToken: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onMessage: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onNotificationOpenedApp: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTokenRefresh: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerDeviceForRemoteMessages: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unregisterDeviceForRemoteMessages: () => {},
})

export type FirebaseMessagingTypes = {
  SendErrorEvent: any
}

export default FirebaseAdapter
