import { BUILD_MODE } from 'src/utils/webAdapters/DotEnvAdapter'
import { InMemoryCache } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'
import { Platform } from 'react-native'
import { setContext } from '@apollo/client/link/context'
import get from 'lodash/get'
import { ApolloClient, createHttpLink, from, fromPromise } from '@apollo/client'
import { Operation } from '@apollo/client/link/core/types'

import { logoutUserWithoutMutations } from 'src/store/user/actions'
import { apiConfig, appVersion } from 'src/utils/apiConfig'
import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import navigationService from 'src/utils/navigationService'
import store from 'src/store'
import translations, { translate } from 'src/utils/translations/translations'
import { getRefreshToken, getToken, setRefreshToken, setToken } from './apolloStorage'
import REFRESH_MUTATION, { TRefreshAccessToken } from 'src/graphql/mutations/refreshToken'
import { GRAPHQL_OPERATION_NAME } from 'src/global/constants'
import { axiosInstance, showErrorModalToken } from 'src/utils/api'

export const API_URL = __DEV__
  ? apiConfig.staging.se.url
  : // @ts-ignore
    get(apiConfig, [[BUILD_MODE || __BUILD_MODE__], 'se', 'url']) || apiConfig.production.se.url

let isRefreshing = false
export const pendingRequests = []
export let client

const setIsRefreshing = (value: boolean) => {
  isRefreshing = value
}

const addPendingRequest = (pendingRequest: any, name = '') => {
  if (name === GRAPHQL_OPERATION_NAME.refreshAccessToken) {
    pendingRequests.unshift(pendingRequest)
  } else {
    pendingRequests.push(pendingRequest)
  }
}

export const clearRequests = () => {
  pendingRequests.length = 0
}

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback())
  clearRequests()
}

export const fetchRefreshToken = async (operation: Operation) => {
  // @ts-ignore
  const refreshResolverResponse = await client
    ?.mutate<TRefreshAccessToken>({
      mutation: REFRESH_MUTATION,
    })
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    .catch(() => {})

  const accessToken = refreshResolverResponse?.data?.refreshAccessToken?.accessToken
  const refreshToken = refreshResolverResponse?.data?.refreshAccessToken?.refreshToken
  setToken(accessToken)
  setRefreshToken(refreshToken)

  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`

  if (operation) {
    const oldHeaders = operation.getContext().headers
    operation.setContext({
      headers: {
        ...oldHeaders,
        Authorization: `Bearer ${accessToken}`,
      },
    })
  }

  return accessToken
}

const isRefreshRequest = (operation?: Operation) =>
  operation?.operationName === GRAPHQL_OPERATION_NAME.refreshAccessToken ||
  operation?.operationName === GRAPHQL_OPERATION_NAME.logout

const returnTokenDependingOnOperation = (operation: Operation) => {
  if (isRefreshRequest(operation)) {
    return getRefreshToken()
  }

  return getToken()
}

export let httpLink = createHttpLink({
  uri: `${API_URL}/graphql`,
})

const clearUser = () => {
  clearRequests()
  setIsRefreshing(false)
  bugsnagActionBreadcrumb('TOKEN error')
  store?.dispatch(logoutUserWithoutMutations())
  showErrorModalToken()
}

export const createClient = (authToken: string, url = API_URL) => {
  setIsRefreshing(false)

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err?.message) {
          case 'Refresh-token is invalid': {
            if (err?.path?.[0] === 'refreshAccessToken') {
              clearUser()
              showErrorModalToken()
              return
            }
            break
          }
          case 'Unauthorized': {
            if (err?.path?.[0] === 'refreshAccessToken') {
              clearUser()
              showErrorModalToken()
              return
            }

            if (!isRefreshing) {
              setIsRefreshing(true)
              return fromPromise(
                fetchRefreshToken(operation).catch(() => {
                  clearUser()
                  return forward(operation)
                }),
              ).flatMap(() => {
                resolvePendingRequests()
                setIsRefreshing(false)
                return forward(operation)
              })
            } else {
              return fromPromise(
                new Promise(resolve => {
                  addPendingRequest(() => resolve(), err?.path?.[0])
                }),
              ).flatMap(() => {
                return forward(operation)
              })
            }
          }
        }
      }
    }

    if (networkError && networkError?.message.includes('Network')) {
      bugsnagActionBreadcrumb('errorLink on ApolloClient - no network (1)')
      return
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        const { message, locations, path } = error

        if (!message) {
          return
        }

        if (__DEV__) {
          console.error(
            `[GraphQL error]: Message: ${JSON.stringify(message)}, Location: ${JSON.stringify(
              locations,
            )}, Path: ${path}`,
          )
        } else {
          navigationService.navigate('Modal', {
            error: `[GraphQL error]: Message: ${JSON.stringify(message)}, Location: ${JSON.stringify(
              locations,
            )}, Path: ${path}`,
            description: translate(translations.reportError),
            title: translate(translations.somethingWentWrong),
          })
        }
      })
    }
  })

  const middlewareLink = setContext((operation, { headers }) => {
    const token = returnTokenDependingOnOperation(operation)

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
        'Student-Origin': `MOBILE_APP_${Platform.OS.toUpperCase()}`,
      },
    }
  })

  httpLink = createHttpLink({
    uri: `${url}/graphql?appVersion=${appVersion}`,
  })

  client = new ApolloClient({
    connectToDevTools: true,
    link: from([errorLink, middlewareLink.concat(httpLink)]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
    },
  })
  return client
}
