import { ApolloLink, Observable, gql } from '@apollo/client'
import debounce from 'lodash/debounce'
import { NativeMMKV } from 'react-native-mmkv/src/MMKV'

import notificationMutation from 'src/graphql/mutations/notificationCallback'
import { OfflineNotificationT } from 'src/utils/pushNotifications/types'

const syncStatusQuery = gql`
  query syncStatus {
    mutations
    inflight
  }
`

export interface AxiosOfflineOptions {
  retryInterval: number
  retryOnServerError: boolean
  sequential: boolean
  storage: NativeMMKV
}

export default class OfflineLink extends ApolloLink {
  private queue: Record<string, any>
  private readonly delayedSync: () => any
  private readonly prefix: string
  private readonly retryOnServerError: boolean
  private readonly sequential: boolean
  private readonly storage: Required<NativeMMKV>

  /**
   * storage
   * Provider that will persist the mutation queue. This can be any AsyncStorage compatible storage instance.
   *
   * retryInterval
   * Milliseconds between attempts to retry failed mutations. Defaults to 30,000 milliseconds.
   *
   * sequential
   * Indicates if the attempts should be retried in order. Defaults to false which retries all failed mutations in parallel.
   *
   * retryOnServerError
   * Indicates if mutations should be reattempted if there are server side errors, useful to retry mutations on session expiration. Defaults to false.
   */

  constructor({ storage, retryInterval = 10000, sequential = true, retryOnServerError = false }: AxiosOfflineOptions) {
    super()

    if (!storage) {
      throw new Error('Storage is required, it can be an AsyncStorage compatible storage instance.')
    }

    this.storage = storage
    this.sequential = sequential
    this.retryOnServerError = retryOnServerError
    this.queue = new Map()
    this.delayedSync = debounce(this.sync, retryInterval)
    this.prefix = 'offlineLink'
  }

  request(operation, forward) {
    const context = operation.getContext(),
      { query, variables } = operation || {}

    if (!context.optimisticResponse) {
      // If the mutation does not have an optimistic response then we don't defer it
      return forward(operation)
    }

    return new Observable(observer => {
      const attemptId = this.add({ mutation: query, variables, optimisticResponse: context.optimisticResponse })
      let subscription
      if (attemptId) {
        subscription = forward(operation).subscribe({
          next: result => {
            // Mutation was successful so we remove it from the queue since we don't need to retry it later
            this.remove(attemptId)

            observer.next(result)
          },

          error: async () => {
            // Mutation failed so we try again after a certain amount of time.
            this.add(operation)

            this.delayedSync()

            // Resolve the mutation with the optimistic response so the UI can be updated
            observer.next({
              data: context.optimisticResponse,
              dataPresent: true,
              errors: [],
            })

            // Say we're all done so the UI is re-rendered.
            observer.complete()
          },

          complete: () => observer.complete(),
        })
      }

      return () => {
        subscription?.unsubscribe()
      }
    })
  }

  /**
   * Obtains the queue of mutations that must be sent to the server.
   * These are kept in a Map to preserve the order of the mutations in the queue.
   */
  async getQueue() {
    let storedAttemptIds = []
    let map

    return new Promise(resolve => {
      // Get all attempt Ids
      if (this.storage.contains(this.prefix + 'AttemptIds')) {
        const storedIds = this.storage.getString(this.prefix + 'AttemptIds')
        map = new Map()

        if (storedIds) {
          storedAttemptIds = storedIds.split(',')

          storedAttemptIds.forEach((storedId, index) => {
            // Get file of name '<prefix><UUID>'
            if (this.storage.contains(this.prefix + storedId)) {
              const stored = this.storage.getString(this.prefix + storedId)
              map?.set(storedId, JSON.parse(stored))

              // We return the map
              if (index === storedAttemptIds.length - 1) {
                resolve(map)
              }
            }
          })
        } else {
          resolve(map)
        }
      } else {
        resolve(new Map())
      }
    })
  }

  /**
   * Persist the queue so mutations can be retried at a later point in time.
   */
  saveQueue(attemptId?: string, item?: OfflineNotificationT) {
    if (attemptId && item) {
      this.storage.set(this.prefix + attemptId, JSON.stringify(item))
    }

    // Saving Ids file
    this.storage.set(this.prefix + 'AttemptIds', [...this.queue.keys()].join())

    this.updateStatus(false)
  }

  /**
   * Updates a SyncStatus object in the Apollo Cache so that the queue status can be obtained and dynamically updated.
   */
  updateStatus(inflight: boolean) {
    this.client.writeQuery({
      query: syncStatusQuery,
      data: {
        __typename: 'SyncStatus',
        mutations: this.queue.size,
        inflight,
      },
    })
  }

  /**
   * Add a mutation attempt to the queue so that it can be retried at a later point in time.
   */
  add(item: OfflineNotificationT) {
    const newKey = `${new Date().getHours()}` + `${new Date().getMinutes()}` + `${new Date().getSeconds()}`
    const attemptId = item?.variables?.messageID || newKey
    const isOpened = item?.variables?.opened ? 'isOpened' : 'isNotOpened'
    const key = `${attemptId}_${isOpened}`
    if (!this.queue.has(key)) {
      this.queue.set(attemptId, item)

      this.saveQueue(attemptId, item)

      return attemptId
    } else {
      return null
    }
  }

  /**
   * Remove a mutation attempt from the queue.
   */
  remove(attemptId: string) {
    this.queue.delete(attemptId)

    this.storage.delete(this.prefix + attemptId)

    this.saveQueue()
  }

  /**
   * Takes the mutations in the queue and try to send them to the server again.
   */
  async sync() {
    const queue = this.queue

    if (queue.size < 1) {
      // There's nothing in the queue to sync, no reason to continue.

      return
    }

    // Update the status to be "in progress"
    this.updateStatus(true)

    // Retry the mutations in the queue, the successful ones are removed from the queue
    if (this.sequential) {
      // Retry the mutations in the order in which they were originally executed

      const attempts = Array.from(queue)

      for (const [attemptId, attempt] of attempts) {
        await this.client
          .mutate({ ...attempt, mutation: notificationMutation })
          .then(() => {
            // Mutation was successfully executed so we remove it from the queue

            this.remove(attemptId)
            return true
          })
          .catch(() => {
            console.error('offline error for', attemptId)
            return false
          })
      }
    } else {
      // Retry mutations in parallel

      await Promise.all(
        Array.from(queue).map(([attemptId, attempt]) => {
          return (
            this.client
              .mutate({ ...attempt, mutation: notificationMutation })
              // Mutation was successfully executed so we remove it from the queue
              .then(() => {
                this.remove(attemptId)
              })

              .catch(() => {
                console.error('offline error for', attemptId)
              })
          )
        }),
      )
    }

    // Remaining mutations in the queue are persisted
    this.saveQueue()
  }

  /**
   * Configure the link to use Apollo Client and immediately try to sync the queue (if there's anything there).
   */
  async setup(client) {
    this.client = client
    this.queue = await this.getQueue()

    return this.sync()
  }
}

export { syncStatusQuery }
