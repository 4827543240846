import React from 'react'
import { Dimensions, Image, Platform, StyleSheet, View } from 'react-native'

import images from 'src/global/images'
import Loader from 'src/scenes/Main/Jobs/Details/components/Loader'
import styles from './styles'
import useStaticMap from 'src/hooks/useStaticMap'

type Props = {
  schoolAddress: string
}

const WEB_DIMENSIONS = { width: 500, height: 200 }
const MAP_PIN_PATH = 'https://assets.humly.io/api/v2/assets/public/se/app_assets/map_pin.png'

const isWeb = Platform.OS === 'web'
const deviceWidth = isWeb ? WEB_DIMENSIONS.width : Math.round(Dimensions.get('window').width)

const SchoolMap = ({ schoolAddress }: Props) => {
  const { staticMapUrl, isLoading, isError } = useStaticMap({
    address: schoolAddress,
    height: isWeb ? WEB_DIMENSIONS.height : styles.backgroundImage.height,
    marker: `icon:${MAP_PIN_PATH}`,
    width: deviceWidth,
    zoom: 12,
  })

  if (isLoading) {
    return (
      <View style={styles.loader}>
        <Loader />
      </View>
    )
  }

  if (isError || !staticMapUrl) {
    return (
      <Image
        source={images.mapFallback}
        style={StyleSheet.flatten([styles.backgroundImage, styles.backgroundFallback])}
        testID="fallback-image"
      />
    )
  }

  return <Image source={{ uri: staticMapUrl }} style={styles.backgroundImage} testID="static-map-image" />
}

export default SchoolMap
