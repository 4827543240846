import AsyncStorage from '@react-native-async-storage/async-storage'
import { AxiosError, AxiosResponse } from 'axios'
import { Platform } from 'react-native'
import get from 'lodash/get'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'

import Api, { axiosInstance, responseErrorCallback } from 'src/utils/api'
import navigationService from 'src/utils/navigationService'
import storage from 'src/mmkvStorage'
import translations, { translate } from 'src/utils/translations/translations'
import { bugsnagActionBreadcrumb, bugsnagNotify, bugsnagNotifyWithData, setBugsnagUser } from 'src/utils/bugsnag'
import { countryCodeSelector } from 'src/store/app/selectors'
import { createUser } from 'src/utils/selectors'
import { EMAIL_ERROR, MIGRATION_ERROR_TITLE, PASSWORD_ERROR } from './constants'
import { LOGOUT_USER, SET_AUTH_TOKEN, SET_USER } from 'src/store/user/types'
import { openURLIfCan } from 'src/global/utils'
import { setAppIsLoading, setCountryCode } from '../app/actions'
import { setDeviceToken } from 'src/store/app/actions'
import { unregisterPushNotifications } from 'src/utils/pushNotifications/utils'
import { UserAttributesT } from './userTypes'
import {
  AgreementT,
  AgreementsResponseT,
  DeleteReferenceArgumentT,
  DislikedSchoolsIdsT,
  DocumentGBArgumentsT,
  DocumentSvArgumentsT,
  DocumentSvArgumentsDispatchT,
  FetchUserArgumentsT,
  HealthQuestionnaireDataT,
  LoginUserArgumentsT,
  PolicyDocumentDataT,
  PolicyDocumentsResponseT,
  SaveUserArgumentsT,
  UpdatePreferencesArgumentsT,
  UpdateUserArgumentsT,
  UpdateWorkReferencesArgumentsT,
  UserResponseT,
} from './actionTypes'
import { AppDispatchT } from '..'
import { ConfigurationResponseData } from 'src/global/types'
import { ErrorResponseT, GetStateT, OnErrorT, OnSuccessT } from 'src/utils/types'
import { TranslationGBT } from 'src/utils/translations/i18nTypes'
import { clearTokens } from 'src/utils/apolloStorage'
import { client } from 'src/utils/ApolloClient'
import countryLocalizationInfo from 'src/global/countryLocalizationInfo'
import logoutMutation from 'src/graphql/mutations/logout'

export const USER_INCLUDES =
  'homeAddress,consents,relative,region,workExperiences,studentEducations,invoicingConfig,workReferences,company,userDocuments,policyDocuments,competences'

export const setUserState = (user: Partial<UserAttributesT>) =>
  ({
    type: SET_USER,
    payload: user,
  } as const)

export const setAuthToken = (authToken: string) =>
  ({
    type: SET_AUTH_TOKEN,
    payload: authToken,
  } as const)

export type ActionTypes = ReturnType<typeof setUserState> | ReturnType<typeof setAuthToken>

//____________________________________________________________ //

export const saveAuthToken = (authToken: string) => {
  if (authToken) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${authToken}`
  }

  return setAuthToken(authToken)
}

export const reportError = (error: string | AxiosError | object) => {
  bugsnagNotifyWithData('reportError', error)
  navigationService.navigate('Modal', {
    error: JSON.stringify(error),
    description: translate(translations.reportError),
    title: translate(translations.somethingWentWrong),
  })
}

export const logout = () => ({
  type: LOGOUT_USER,
})

const clearTokensAndLS = async (dispatch?: AppDispatchT) => {
  await AsyncStorage?.multiRemove([
    'persist:app',
    'persist:user',
    'deviceToken',
    'isIosNotificationUpdateDisplayed',
    'isAvailabilityTipShowed',
  ])
  clearTokens()
  setBugsnagUser('null')
  client?.clearStore?.()
  storage.delete('currency')
  storage.delete('locale')
  storage.delete('timezone')
  if (dispatch) dispatch(logout())
}

export const logoutUser = () => (dispatch: AppDispatchT, getState: GetStateT) => {
  const deviceToken = getState()?.app?.deviceToken as string

  bugsnagActionBreadcrumb('Logout user')

  const removeActions = async () => {
    await unregisterPushNotifications()

    client
      ?.mutate({
        mutation: logoutMutation,
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {})

    if (deviceToken) {
      await removeDeviceToken(deviceToken, () => {
        if (dispatch) dispatch(setDeviceToken(null))
      })
    }

    await clearTokensAndLS(dispatch)
  }

  if (deviceToken) {
    return Api.delete('sessions/logout', {
      data: {
        authToken: deviceToken,
      },
      onError: (error: AxiosError<ErrorResponseT>) => {
        if (!error?.message?.includes('401')) {
          bugsnagNotify(error)
        }
      },
    }).then(async () => await removeActions())
  }

  return removeActions()
}

export const logoutUserWithoutMutations = () => (dispatch: AppDispatchT, getState: GetStateT) => {
  const deviceToken = getState()?.app?.deviceToken as string

  bugsnagActionBreadcrumb('Logout user')

  const removeActions = async () => {
    await unregisterPushNotifications()

    if (deviceToken) {
      await removeDeviceToken(deviceToken, () => {
        if (dispatch) dispatch(setDeviceToken(null))
      })
    }

    await clearTokensAndLS(dispatch)
  }

  if (deviceToken) {
    return Api.delete('sessions/logout', {
      data: {
        authToken: deviceToken,
      },
      onError: (error: AxiosError<ErrorResponseT>) => {
        if (!error?.message?.includes('401')) {
          bugsnagNotify(error)
        }
      },
    }).then(async () => await removeActions())
  }

  return removeActions()
}

export const logoutUserWithoutInternet = () => (dispatch: AppDispatchT, getState: GetStateT) => {
  const deviceToken = getState()?.app?.deviceToken as string
  bugsnagActionBreadcrumb('Logout user')
  const removeActions = async () => {
    if (deviceToken && dispatch) {
      dispatch(setDeviceToken(null))
    }

    await clearTokensAndLS(dispatch)
  }

  if (deviceToken) {
    return Api.delete('sessions/logout', {
      data: {
        authToken: deviceToken,
      },
      onError: (error: AxiosError<ErrorResponseT>) => {
        if (!error?.message?.includes('401')) {
          bugsnagNotify(error)
        }
      },
    }).then(async () => await removeActions())
  }

  return removeActions()
}

export const logoutActions = (dispatch: AppDispatchT) => dispatch(logoutUser())

export const removeDeviceToken = (deviceToken: string, onSuccess: OnSuccessT) => {
  bugsnagActionBreadcrumb('removeDeviceToken')

  return deviceToken
    ? Api.delete(`/installations/${deviceToken}`, {
        onError: () => {
          bugsnagNotifyWithData('removeDeviceToken error', { deviceToken })
        },
        onSuccess,
      })
    : null
}

export const updatePreferences =
  (payload: Partial<UpdatePreferencesArgumentsT['payload']>, onError?: UpdatePreferencesArgumentsT['onError']) =>
  (dispatch: AppDispatchT, getState: GetStateT): Promise<AxiosResponse<string> | void> => {
    bugsnagActionBreadcrumb('updatePreferences')
    const userId = getState().user?.id

    return Api.put(`users/${userId}?include=${USER_INCLUDES}`, {
      data: JSON.stringify({ user: payload }),
      onSuccess: ({ data }: AxiosResponse<UserResponseT>) => {
        const countryCode = countryCodeSelector(getState())
        const user = createUser(data, countryCode)
        bugsnagActionBreadcrumb('setUser')
        dispatch(setUserState(user))
      },

      onError: ({ response }: AxiosError<ErrorResponseT>) => {
        bugsnagNotifyWithData('updatePreferences', response)
        responseErrorCallback('put', `users/${userId}`, response as AxiosResponse)
        onError && onError(response)
      },
    })
  }

export const fetchAgreements =
  (onSuccess: (agreements: AgreementT[]) => void) => async (dispatch: AppDispatchT, getState: GetStateT) => {
    const countryCode = countryCodeSelector(getState())

    return Api.get(`/agreements?countryCode=${countryCode}`, {
      onSuccess: ({ data: { data } }: AxiosResponse<AgreementsResponseT>) => onSuccess(data),
      onError: () => bugsnagNotifyWithData('fetchAgreements'),
    })
  }

export const fetchUserRequest = ({ dispatch, getState, id, onSuccess, setUser }: FetchUserArgumentsT) => {
  const userId = id || getState().user.id
  const countryCode = countryCodeSelector(getState())
  bugsnagActionBreadcrumb('fetchUser', { id: userId })

  return Api.get(`users/${userId}?include=${USER_INCLUDES}`, {
    onSuccess: async ({ data }: AxiosResponse<UserResponseT>) => {
      dispatch(setAppIsLoading(false))

      let user
      try {
        user = createUser(data, countryCode)

        dispatch(setCountryCode(countryCode))

        if (user.attributes.active) {
          dispatch(setUser(user, countryCode))
        } else {
          dispatch(logoutUser())
        }
      } catch (e) {
        bugsnagNotifyWithData('fetchUser catch', e)
        reportError(e || '')
        logoutActions(dispatch)
      }

      if (onSuccess) {
        onSuccess(user)
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {},
  }).then(() => dispatch(setAppIsLoading(false)))
}

export const loginUserRequest = ({
  countryCode,
  email,
  password,
  dispatch,
  setUser,
  setErrors,
}: LoginUserArgumentsT) => {
  bugsnagActionBreadcrumb('Login user', { email, countryCode })

  AsyncStorage.setItem('workloadFilled', 'not_loaded')

  return Api.post(`sessions?include=${USER_INCLUDES}`, {
    data: {
      email: email.trim(),
      password: password.trim(),
    },
    onSuccess: async ({ data }: AxiosResponse<UserResponseT>) => {
      storage.set('currency', countryLocalizationInfo[countryCode].currency)
      storage.set('locale', countryLocalizationInfo[countryCode].locale)
      storage.set('timezone', countryLocalizationInfo[countryCode].timezone)

      const user = createUser(data, countryCode)

      dispatch(setUser(user, countryCode))
      dispatch(setCountryCode(countryCode))

      const reportingId = user?.attributes?.reportingId || 'NO REPORTING ID'
      setBugsnagUser(reportingId)
    },
    onError: (response: AxiosError<ErrorResponseT>) => {
      const errors = response?.response?.data?.errors
      const migrationError = errors?.find(error => error?.title === MIGRATION_ERROR_TITLE)

      if (migrationError) {
        navigationService.navigate('Modal', {
          migrationEstimatedTime: migrationError.detail,
          serverMaintenanceModal: true,
        })
      } else if (response && response.toString().includes('timeout')) {
        navigationService.navigate('Modal', {
          title: translate(translations.somethingWentWrong),
          description: translate(translations.modalWeakConnectionMessage),
        })
      } else if (!get(response, 'response') && !head(errors)?.detail && !head(errors)?.title) {
        navigationService.navigate('Modal', {
          title: translate(translations.serverUnavailableTitle),
          description: translate(translations.serverUnavailable),
        })
      } else {
        try {
          const detail = head(errors)?.detail || ''
          const title = head(errors)?.title || ''

          if (!isEmpty(detail)) {
            if (title === PASSWORD_ERROR.gb || title === PASSWORD_ERROR.sv) {
              setErrors({ errorMessage: translate(translations.loginPasswordError) })
            } else if (title === EMAIL_ERROR.gb || title === EMAIL_ERROR.sv) {
              setErrors({ errorMessage: translate(translations.loginEmailError) })
            } else {
              navigationService.navigate('Modal', { title, description: detail })
            }
          } else {
            navigationService.navigate('Modal', {
              error: `Login failed with ${response}`,
              description: translate(translations.reportError),
              title: translate(translations.somethingWentWrong),
            })
          }
        } catch (_) {
          bugsnagNotifyWithData('Login error', { response })

          navigationService.navigate('Modal', {
            description: translate(translations.internalErrorDescription),
            title: translate(translations.internalError),
          })
        }
      }
    },
  })
}

export const verifyPassword = (email: string, password: string, onSuccess: OnSuccessT, onError: OnErrorT) => {
  bugsnagActionBreadcrumb('Verify password')

  return Api.post(`sessions?include=${USER_INCLUDES}`, {
    data: {
      email: email.trim(),
      password: password.trim(),
    },
    onSuccess: () => {
      onSuccess && onSuccess()
    },
    onError: ({ response }: AxiosError<ErrorResponseT>) => {
      onError && onError()

      const errors = response?.data?.errors
      const title = head(errors)?.title || ''

      if (title === 'Wrong password') {
        bugsnagActionBreadcrumb('go to modal with wrong password')

        navigationService.navigate('Modal', {
          danger: true,
          description: translate((translations as TranslationGBT).passwordConfirmationFailed),
          title,
        })
      } else {
        bugsnagNotifyWithData('verifyPassword', { response })

        responseErrorCallback('post', `sessions?include=${USER_INCLUDES}`, response as AxiosResponse)
      }
    },
  })
}

export const removeAccount = () => {
  bugsnagActionBreadcrumb('removeAccount')

  return Api.post('/student/account_deletion', {
    onError: () => {
      bugsnagNotifyWithData('removeAccount error')
    },
  })
}

export const sendRegistrationError = (error: string) =>
  navigationService.navigate('Modal', {
    description: translate(translations.notificationError),
    error,
    notificationsModal: true,
    title: translate(translations.somethingWentWrong),
  })

export const registerDeviceToken = (deviceToken: string, userId: string) => {
  bugsnagActionBreadcrumb('registerDeviceToken', { deviceToken, userId })

  return Api.post('/installations', {
    data: {
      installation: {
        deviceToken,
        deviceType: Platform.OS,
        newFirebase: true,
        user: userId,
      },
    },
    onError: ({ response }: AxiosError<ErrorResponseT>) => {
      bugsnagNotifyWithData('registerDeviceToken', response)
      sendRegistrationError(JSON.stringify(response))
    },
  })
}

export const sendFeedback = (content: string, onSuccess: OnSuccessT) =>
  Api.post('/student/notes', {
    data: {
      note: {
        content,
      },
    },
    onSuccess: () => onSuccess(),
    onError: () => bugsnagNotifyWithData('sendFeedback', { content }),
  })

export const updateUserRequest = ({
  countryCode,
  dispatch,
  id,
  newUser,
  onError,
  onSuccess,
  profilePictureUrl,
  setUser,
  stringifyData,
  uri,
}: UpdateUserArgumentsT) => {
  bugsnagActionBreadcrumb('updateUser')

  return Api.put(`users/${id}?include=${USER_INCLUDES}`, {
    data: stringifyData
      ? JSON.stringify({
          user: newUser,
        })
      : {
          user: newUser,
        },
    onSuccess: ({ data }: AxiosResponse<UserResponseT>) => {
      const user = createUser(data, countryCode)
      dispatch(setUser(user, countryCode))

      if (uri && !profilePictureUrl) {
        navigationService.navigate('Modal', {
          description: translate(translations.avatarError),
          title: translate(translations.pleaseTryAgain),
        })
      }
      onSuccess && onSuccess()
    },
    onError: ({ response }: AxiosError<ErrorResponseT>) => {
      bugsnagNotifyWithData('updateUser', response)
      responseErrorCallback('put', `users/${id}?include=${USER_INCLUDES}`, response as AxiosResponse)
      onError && onError(response)
    },
  }).catch(error => console.error(error))
}

export const saveUserRequest = ({
  dispatch,
  fileName,
  getState,
  id,
  onError,
  onSuccess,
  setUser,
  stringifyData = false,
  uploadAvatarToS3,
  uri,
  user,
}: SaveUserArgumentsT) => {
  const newUser = (user || getState().user?.attributes) as UserAttributesT
  const countryCode = countryCodeSelector(getState())

  bugsnagActionBreadcrumb('saveUser')

  if (uri) {
    uploadAvatarToS3({ uri, id, fileName }).then((profilePictureUrl: string) => {
      if (profilePictureUrl) {
        newUser.profilePictureUrl = profilePictureUrl
      }

      return updateUserRequest({
        countryCode,
        dispatch,
        id,
        newUser,
        onError,
        onSuccess,
        profilePictureUrl,
        setUser,
        stringifyData,
        uri,
      })
    })
  } else {
    return updateUserRequest({
      countryCode,
      dispatch,
      onSuccess,
      onError,
      id,
      newUser,
      setUser,
      stringifyData,
    })
  }
}

export const getServerMaintenanceInfo = () =>
  Api.get(`/configuration`, {
    onSuccess: (data: AxiosResponse<ConfigurationResponseData>) => {
      const isServerMigration = data.data?.metadata?.migration_mode || false
      const migrationEstimatedTime = data.data?.metadata?.migration_estimated_completed_at

      if (isServerMigration) {
        navigationService.navigate('Modal', {
          migrationEstimatedTime,
          serverMaintenanceModal: true,
        })
      }
    },
    onError: (response: AxiosResponse) => {
      responseErrorCallback('get', `/configuration`, response)
    },
  })

export const removeDocumentUK = (id: string) => {
  bugsnagActionBreadcrumb('removeDocumentUK', { id })

  return Api.delete(`student/user_documents/${id}`)
}

export const removeDocumentSvRequest = ({ dispatch, field, onSuccess, url, userId }: DocumentSvArgumentsDispatchT) => {
  bugsnagActionBreadcrumb('removeDocumentSv', { id: userId })

  return Api.delete(`/student/assign_urls/${userId}?field=${field}&url=${url}`, {
    onSuccess: async ({ data }: AxiosResponse<UserResponseT>) => {
      const user = createUser(data, 'se')
      bugsnagActionBreadcrumb('setUser')
      dispatch(setUserState(user))
      onSuccess && onSuccess()
    },
  })
}

export const removeOtherSvDocument = (url: string, onSuccess?: OnSuccessT) => {
  bugsnagActionBreadcrumb('delete other sv document')

  return Api.delete(`/student/file_removal?path=${url}`, {
    onSuccess: () => {
      onSuccess && onSuccess()
    },
  })
}

export const removeDocumentSv =
  ({ field, onSuccess, url, userId }: DocumentSvArgumentsT) =>
  (dispatch: AppDispatchT) =>
    removeDocumentSvRequest({ dispatch, field, onSuccess, url, userId })

export const uploadDocumentUK = (document: DocumentGBArgumentsT) => {
  bugsnagActionBreadcrumb('uploadDocumentUK')

  return Api.post('student/user_documents', {
    data: { user_document: document },
  })
}

export const uploadDocumentSvRequest = ({
  dispatch,
  field,
  onError,
  onSuccess,
  url,
  userId,
}: DocumentSvArgumentsDispatchT) => {
  bugsnagActionBreadcrumb('uploadDocumentSv')

  Api.put(`/student/assign_urls/${userId}`, {
    data: JSON.stringify({
      field,
      url,
    }),
    onSuccess: async ({ data }: AxiosResponse<UserResponseT>) => {
      const user = createUser(data, 'se')
      bugsnagActionBreadcrumb('setUser')
      dispatch(setUserState(user))
      onSuccess && onSuccess()
    },
    onError: ({ response }: { response: AxiosError<ErrorResponseT> }) => {
      bugsnagNotifyWithData('uploadDocumentSv', response)
      responseErrorCallback('put', `/student/assign_urls/${userId}`, response)
      onError && onError()
    },
  })
}

export const uploadDocumentSv =
  ({ field, onError, onSuccess, url, userId }: DocumentSvArgumentsT) =>
  (dispatch: AppDispatchT) =>
    uploadDocumentSvRequest({ dispatch, field, onError, onSuccess, url, userId })

export const updateUserSchools = (data: DislikedSchoolsIdsT) => (dispatch: AppDispatchT, getState: GetStateT) => {
  bugsnagActionBreadcrumb('updateUserSchools')

  return Api.put(`users/${getState().user.id}`, {
    data: JSON.stringify({
      user: data,
    }),
  })
}

export const updateWorkReferencesRequest = ({
  fetchUser,
  references,
  userId,
  onSuccess,
  onError,
  dispatch,
}: UpdateWorkReferencesArgumentsT) => {
  bugsnagActionBreadcrumb('updateWorkReferences')

  return Api.post(`student/${userId}/work_references`, {
    data: JSON.stringify(references),
    onSuccess: () => {
      dispatch(fetchUser(userId))
      onSuccess && onSuccess()
    },
    onError: ({ response }: AxiosError<ErrorResponseT>) => {
      bugsnagNotifyWithData('updateWorkReferences', response)
      responseErrorCallback('post', `student/${userId}/work_references`, response as AxiosResponse)
      onError && onError()
    },
  })
}

export const deleteWorkReference = ({ referenceId, onSuccess, onError }: DeleteReferenceArgumentT) => {
  bugsnagActionBreadcrumb('deleteWorkReference')

  return Api.delete(`/student/work_references/${referenceId}`, {
    data: JSON.stringify(referenceId),
    onSuccess: () => {
      onSuccess && onSuccess()
    },
    onError: ({ response }: AxiosError<ErrorResponseT>) => {
      bugsnagNotifyWithData('deleteWorkReferences', response)
      onError && onError()
    },
  })
}

export const getPolicyDocument = (type: 'keeping_children_safe_policy' | 'safeguarding_policy', onError?: OnErrorT) =>
  Api.get(`/student/policy_documents?type=${type}`, {
    onSuccess: async ({ data }: AxiosResponse<PolicyDocumentsResponseT>) => {
      await openURLIfCan(data.data[0].attributes.url)
    },
    onError: () => onError && onError(),
  })

export const updateReadDocuments = (userId: string, policies: string[], onSuccess: OnSuccessT, onError: OnErrorT) =>
  Api.put(`users/${userId}`, {
    data: JSON.stringify({
      user: { acceptedPolicyDocumentTypes: policies },
    }),
    onSuccess: () => {
      onSuccess && onSuccess()
    },
    onError: () => {
      onError && onError()
    },
  })

export const fetchStudentAgreements = (type: string, onSuccess: (data: PolicyDocumentDataT[]) => void) => async () => {
  bugsnagActionBreadcrumb('fetchStudentAgreements')

  return Api.get(`student/policy_documents?type=${type}`, {
    onSuccess: ({ data: { data } }: AxiosResponse<PolicyDocumentsResponseT>) => {
      onSuccess(data)
    },
  })
}

export const createHealthQuestionnaire = (
  healthQuestionnaire: HealthQuestionnaireDataT,
  onSuccess?: OnSuccessT,
  onError?: OnErrorT,
) =>
  Api.post('student/health_questionnaires', {
    data: { healthQuestionnaire },
    onSuccess: () => onSuccess && onSuccess(),
    onError: () => onError && onError(),
  })
