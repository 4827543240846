import React, { useEffect, useRef } from 'react'
import { View } from 'react-native'
import LottieView from 'lottie-react-native'

import styles from './styles'
import SuccessAnimationRender from 'src/scenes/Main/Salary/SuccessAnimation/SuccessAnimationRender'

const REDIRECT_TIME = 2000

type Props = {
  animationInfo: string
  duration?: number
  onAnimationEnd: () => void
  showAnimation: boolean
}

const SuccessAnimation = ({ animationInfo, onAnimationEnd, showAnimation, duration }: Props) => {
  const timer = useRef<NodeJS.Timeout>()
  const refAnimation = useRef<LottieView | null>(null)

  useEffect(() => {
    const currentRefAnimation = refAnimation?.current
    if (showAnimation) {
      if (currentRefAnimation) {
        currentRefAnimation.play()
      }
      timer.current = setTimeout(() => onAnimationEnd(), duration || REDIRECT_TIME)
    }
    return () => {
      if (timer.current) {
        currentRefAnimation?.reset()
        clearTimeout(timer.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAnimation])

  return showAnimation ? (
    <View style={styles.screen} testID="success-animation">
      <SuccessAnimationRender
        onAnimationFinish={onAnimationEnd}
        refAnimation={refAnimation}
        successText={animationInfo}
      />
    </View>
  ) : null
}

export default SuccessAnimation
