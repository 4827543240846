import { applyMiddleware, compose, createStore, AnyAction } from 'redux'
import thunkMiddleware, { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { persistStore } from 'redux-persist'

import reducers from './reducers'

const middlewares = [thunkMiddleware]

//@ts-ignore
const composeEnhancer = __DEV__ ? window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose

const store = createStore(reducers, composeEnhancer(applyMiddleware(...middlewares)))

export type StoreType = ReturnType<typeof store.getState>
export type TypedDispatch = ThunkDispatch<StoreType, any, AnyAction>
export type TypedThunk<ReturnType = void> = ThunkAction<ReturnType, StoreType, unknown, AnyAction>
export type AppDispatchT = typeof store.dispatch

export const persistor = persistStore(store)

export default store
